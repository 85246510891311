.iFollow{
    background-color:gold !important
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: #1a97ec !important;
}

.rangeslider-horizontal {
    height: 6px !important;
}

.listingIcon{
    color: #f14300;
    padding: 10px;
}

.dateListing{
border: 1.5px solid hsla(0,0%,100%,.3);
border-radius: 5px;
padding:6px
}

.oneLine {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;     /* fallback */
    max-height: 32px;      /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.twoLines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;     /* fallback */
    max-height: 32px;      /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.images:hover{
    .imageHover{
        transform:scale(1.05);
        transition: transform 1s ease-in-out;
    }
}

.headerHome {
    color: white;
    position: absolute;
    width:100%;
    padding: 1em;
    top:3px;
    .date {
        position:absolute;
        left:3px;
        font-size: 15px;
    }
    .time{
        position:absolute;
        left: 3px;
        top:25px;
    }
}