// Variables
$regal-blue: #034378;
$san-juan: #2d4e68;
$bermuda: #77d7b9;
$white: #fff;
$black: #000;
$open-sans: 'Open Sans',
sans-serif;
// clear-fix mixin
@mixin cf {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  }
}

body{
  color:black !important;
}

* {
  box-sizing: border-box;
}

.eventOverlay{
        width: 100%;
        height: 100%;
        background-color:#000;
        opacity: 0.6;
        position: absolute;

    }

    .eventOverlayHomePage{
        width: 100%;
        /* height: 100%; */
        background-color:#000;
        opacity: 0.6;
        position: absolute;

    }
    .banner-list p {
      float:none !important;
    }

// Base styles
.event-card {
  float: left;
  width: 100%;
  .menu-content {
    @include cf;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: inline-block;
    }
    a {
      color: $white;
    }
    span {
      position: absolute;
      left: 50%;
      top: 0;
      font-size: 10px;
      font-weight: 700;
      font-family: 'Open Sans';
      transform: translate(-50%, 0);
    }
  }
  .wrapper {
    background-color: $white;
    height: 350px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.2);
    
    &:hover {
     .show{
        opacity: 1;
        color:#034378
      }
      .data {
        transform: translateY(0);
      }
      
    }
  }
  .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(calc(70px + 1em));
    transition: transform 0.3s;
    .content {
      padding: 1em;
      position: relative;
      z-index: 1;

      .text {
       overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;     /* fallback */
        max-height: 32px;      /* fallback */
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    }
  }
  .author {
    font-size: 12px;
  }
  .title {
    margin-top: 10px;
  }
  
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox']:checked + .menu-content {
    transform: translateY(-60px);
  }
}

.example-2 {
  .wrapper {
    &:hover { 
      .menu-content {
        span {
          transform: translate(-50%, -10px);
          opacity: 1;
        }
      }
    }
  }
  .header {
    @include cf;
    color: $white;
    position: absolute;
    width:100%;
    padding: 1em;
    .date {
        position:absolute;
        left:3px;
        font-size: 15px;
    }
    .time{
        position:absolute;
        left: 3px;
        top:25px;
    }
  }
  .menu-content {
    float: right;
    li {
      margin: 0 5px;
      position: relative;
    }
    span {
      transition: all 0.3s;
      opacity: 0;
    }
  }
  .data {
    color: $white;
    transform: translateY(calc(-30px + 4em));
  }
  .title {
    a {
      color: $white;
    }
  }
  .button {
    display: block;
    width: 100px;
    margin: 2em auto 1em;
    text-align: center;
    font-size: 12px;
    color: $white;
    line-height: 1;
    position: relative;
    font-weight: 700;
    &::after {
      content: '\2192';
      opacity: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      transition: all 0.3s;
    }
    &:hover {
      &::after {
        transform: translate(5px, -50%);
        opacity: 1;
      }
    }
  }
}

/*=============== Table Device ===============*/

@media (max-width: 800px) and (min-width: 200px) {

  .comment-rating,.comment-text{
   padding:15px !important;
  }
}



@media (max-width: 575px) {

  .gallery-slider{
    a.btn-primary{
      float: none !important;
      margin-top:10px;
    }
  }

  .myResponsiveContainer{
  width: 100% !important;
  padding: 0 !important;

   .listing-details-area{
  padding: 1px !important;
    .myContainer2{
      width: 100% !important;
    padding: 0 !important;
    }
 }

 .p-5{
  padding: 1px !important;
 }
 }

}

/*=============== Mobile Device ===============*/
@media (max-width: 480px) {
  
 .gallery-slider{
    a.btn-primary{
      float: none !important;
      margin-top:10px;
    }
  }

 .myResponsiveContainer{
  width: 100% !important;
  padding: 0 !important;

  .listing-details-area{
  padding: 1px !important;
   .myContainer2{
      width: 100% !important;
    padding: 0 !important;
    }
 }
 .p-5{
  padding: 1px !important;
 }
 }
 
}