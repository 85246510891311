.explore_area{

.exploreActive{
    border-top: 2px solid #dddddd;
    font-weight: 800;
    outline: none;
}

.nav-link:hover{
    color:gray;
    outline: none;
}

.responsiveLink{
        text-align:center !important;
    }

}

@media (max-width: 991px) {
    .banner-form{
        width: 600px;
    }
    a.serachingFor{
        display: none;
    }
    .me-auto{
        width: 100% !important;
        overflow-x:scroll;
    }

    .responsivePhone{
        display: block !important;
    }

    .responsiveNavBrand{
        display:none !important;
    }

    .responsiveFilter{
       display:none !important; 
    }

    .responsivePadding{
        margin-top:30px !important;
    }

    .responsiveNoPadding{
     padding-left:0px !important;
     padding-right: 0px !important;
    }

    .responsiveFilterButton{
        display: block !important;
    }
}

@media (max-width: 600px) {
    .banner-form {
        width: 300px;
    }
}

@media (max-width: 400px) {
    .banner-form {
        width: 300px;
    }
}


