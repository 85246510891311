/*=============== Mobile Device ===============*/

@media (max-width: 200px) {

    .dynamic_operating_times{
        margin-top:260px !important;
    }

}

@media (max-width: 450px) and (min-width: 200px) {

    .dynamic_operating_times{
        margin-top:200px !important;
    }

}

/*=============== Table Device ===============*/
@media (max-width: 990px) and (min-width: 451px) {
.dynamic_operating_times{
        margin-top:150px !important;
    }
}


@media (min-width: 991px) {

    .dynamic_operating_times{
        margin-top:100px !important;
    }

}