/*
* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>


*******************************************
*******************************************
** - Default Btn Style 
** - Section Title Style
** - Navbar Area Style
** - Home Slider Area Style
** - Inner Banner CSS Style
** - Other CSS Style
*******************************************
/*

/*================================================
Default CSS
=================================================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
@import url('../fonts/ink-free-normal.ttf');
:root {
    --fontFamily: 'Poppins', sans-serif;
    --sub-fontFamily: 'Ink Free';
    --mainColor: #f14300;
    --paragraphColor: #8c8c8c;
    --whiteColor: #ffffff;
    --titleColor: #2a2a2a;
    --optionalColor: #ffc0a7;
    --fontSize: 16px;
    --transition: .5s;
}
body {
    padding: 0;
    margin: 0;
    font: {
            size: var(--fontSize);
            family: var(--fontFamily);
    };
}
p {
    color: var(--paragraphColor);
}
a {
    display: inline-block;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-decoration: none;
    &:hover, &:focus {
        text-decoration: none;
    }
}
button {
    margin: 0;
    padding: 0;
    outline: 0;
    &:focus {
        outline: 0 ;
        border: 0;
    }
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: var(--titleColor);
    line-height: 1.4;
}
h3 {
    font-size: 20px;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    display: table-cell;
    vertical-align: middle;
}
img {
    max-width: 100%;
}
.border-radius {
    border-radius: 50px;
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.ptb-700 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pt-45 {
    padding-top: 45px;
}
.mb-45 {
    margin-bottom: 45px;
}
.bg-dark-orange {
    background-color: var(--mainColor);
}
.bg-color-blue {
    background-color: #2d5eff;
}
.bg-color-heliotrope {
    background-color: #cf3cff;
}
.bg-color-green {
    background-color: #028311;
}
.text-color-white {
    color: var(--whiteColor) !important;
}
.margin-auto {
    margin: 0 auto;
}
/*================================
Default Btn Style 
===================================*/
.default-btn {
    padding: 12px 27px;
    color: var(--whiteColor);
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-transform: capitalize;
    background-color: var(--mainColor);
    border: none;
    outline: none;
    i {
        margin-left: 5px;
    }
    &::before {
        content: "";
        position: absolute;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: var(--titleColor);
        z-index: -1;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }      
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 100%;
        background-color: var(--titleColor);
        z-index: -1;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }
    &:hover {
        color: var(--whiteColor);
        border: none;
    }
    &:hover::before {
        left: auto;
        right: 0;
        width: 50%;
     }
      
    &:hover::after {
        left: 0;
        right: 0;
        width: 50%;
    }
}
/*================================
Default Btn Style End
===================================*/

/*================================
Section Title Style 
===================================*/
.section-title {
    span {
        color: var(--mainColor);
        font-family: var(--sub-fontFamily);
        line-height: 0;
        font-size: 16px;
        text-transform: capitalize;
        margin: 0;
        padding-bottom: 5px;
        font-weight: 600;
    }
    h2 {
        font-size: 38px;
        font-weight: 500;
        margin-top: 5px;
        line-height: 1.5;
        b {
            font-weight: 300;
        }
    }
    p {
        padding-top: 10px;
        margin-bottom: 0;
    }
}
/*================================
Section Title Style End
===================================*/

/*=================================
Navbar Area
====================================*/
.navbar-area {
    background-color: transparent;
    transition: 0.3s;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
}
.navbar-light {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    .navbar-brand {
        img{
            float: left;
            padding: 5px 0;
        }
    }
    .navbar-brand-sticky {
        display: none;
    }
}
/* Main nav */
.main-nav {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    padding:  0;
    width: 100%;
    z-index: 999;
    height: auto;
    nav {
        .navbar-nav {
            .nav-item {
                &:hover a,.active {
                    color: var(--mainColor) !important;
                }
                a {
                    text-transform: capitalize;
                    color: var(--whiteColor);
                    font-weight: 400;
                    margin-left: 12px;
                    margin-right: 12px;
                    i {
                        line-height: 0;
                        position: relative;
                        top: 3px;
                        font-size: 18px;
                    }
                    &:hover, :focus {
                        color: var(--mainColor) !important;
                    }
                    &.active {
                        color: var(--mainColor) !important;
                    }
                    .active::before {
                        transform: scale(1);
                    }
                }
                .dropdown-menu {
                    border: none;
                    border-top: 3px solid var(--mainColor);
                    padding: 0;
                    border-radius: 0;
                    background-color: var(--whiteColor) !important;
                    li {
                        border-bottom: 1px solid var(--whiteColor);
                        &:last-child {
                            border-bottom: none;
                        }
                        a {
                            text-transform: capitalize;
                            color: var(--titleColor) !important;
                            position: relative;
                            transition: 0.7s;
                            font-weight: 400;
                            padding: 10px;
                            border-bottom: 1px dashed #ebebeb;
                            &.active{
                                border-radius: .25rem; 
                                color: var(--mainColor) !important;
                            }
                            &:hover, :focus, .active{
                                color: var(--mainColor) !important;
                                margin-left: 5px;
                                border-radius: .25rem;
                            }
                        }
                        &.active{
                           color: var(--mainColor) !important;
                        }
                        .dropdown-menu {
                            left: 100%;
                            top: 20px;
                            margin-top: 18px;
                            li {
                                .dropdown-menu {
                                    left: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .side-nav {
            padding: 10px 0 3px;
            margin-left: 30px;
            .side-item {
                padding-left: 15px;
                position: relative;
                display: inline-block;
                .cart-btn {
                    position: relative;
                    top: -15px;
                    i {
                        display: inline-block;
                        font-size: 25px;
                        color: var(--whiteColor);
                        border-radius: 50px;
                        text-align: center;
                    }
                    span {
                        position: absolute;
                        right: 0;
                        bottom: 0px;
                        width: 18px;
                        height: 18px;
                        text-align: center;
                        line-height: 20px;
                        border-radius: 50%;
                        background-color: var(--whiteColor);
                        color: var(--mainColor);
                        font-size: 12px;
                        font-weight: 500; 
                    }
                }
                .search-box {
                    position: relative;
                    top: -12px;
                    i {
                        cursor: pointer;
                        font-size: 18px;
                        color: var(--whiteColor);
                        position: relative;
                        display: inline-block;
                        top: -2px;
                        line-height: 35px;
                        border-radius: 50px;
                        width: 35px;
                        height: 35px;
                        border: 1px solid var(--whiteColor);
                        text-align: center;
                        transition: var(--transition);
                    }
                    &:hover i {
                        border-color: var(--mainColor);
                        color: var(--mainColor);
                    }            
                }
                .user-btn {
                    position: relative;
                    top: -12px;
                    i {
                        cursor: pointer;
                        font-size: 18px;
                        color: var(--whiteColor);
                        position: relative;
                        display: inline-block;
                        top: -2px;
                        line-height: 35px;
                        border-radius: 50px;
                        width: 35px;
                        height: 35px;
                        border: 1px solid var(--whiteColor);
                        text-align: center;
                        transition: var(--transition);
                    }
                    &:hover i {
                        border-color: var(--mainColor);
                        color: var(--mainColor);
                    }         
                }
            }
        }
    }
}
.nav-two {
    position: inherit;
    background-color: var(--whiteColor);
    nav {
        .navbar-nav {
            .nav-item {
                a {
                    color: var(--titleColor);
                }
            }
        }
        .side-nav {
            .side-item {
                .cart-btn {
                    i {
                        color: var(--mainColor);
                    }
                    span {
                        background-color: var(--mainColor);
                        color: var(--whiteColor); 
                    }
                    &:hover {
                        i {
                            color:  var(--titleColor);
                        }  
                        span {
                            background-color:  var(--titleColor);
                        }  

                    } 
                }
                .search-box {
                    i {
                        color: var(--mainColor);
                        border: 1px solid var(--mainColor);
                    }
                    &:hover i {
                        border-color: var(--titleColor);
                        color:  var(--titleColor);
                    }          
                }
                .user-btn {
                    position: relative;
                    top: -12px;
                    i {
                        color: var(--mainColor);
                        border: 1px solid var(--mainColor);
                    }
                    &:hover i {
                        border-color: var(--titleColor);
                        color:  var(--titleColor);
                    }    
                }
            }
        }
    }
}

.side-nav-responsive {
	display: none;
	.dot-menu {
		padding: 0 10px;
		height: 30px;
		cursor: pointer;
		z-index: 9999;
		position: absolute;
		right: 60px;
        top: 12px;
		.circle-inner {
			display: flex;
			align-items: center;
			height: 30px;
			.circle {
				height: 5px;
				width: 5px;
				border-radius: 100%;
				margin: 0 2px;
				transition: var(--transition);
				background-color: var(--whiteColor);
			}
		}
		&:hover {
			.circle-inner {
				.circle {
					background-color: var(--mainColor);
				}
			}
		}
	}
	.container {
        position: relative;
        display: flex;
		.container {
			position: absolute;
			right: 0;
			top: 55px;
			max-width: 300px;
			margin-left: auto;
			opacity: 0;
			visibility: hidden;
			transition: var(--transition);
			transform: scaleX(0);
			z-index: 2;
			padding-left: 15px;
			padding-right: 15px;
		}
		.container.active {
			opacity: 1;
			visibility: visible;
			transform: scaleX(1);
		}
	}
	.side-nav-inner {
		padding: 10px;
		-webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
		box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
		background-color: var(--whiteColor);
		.side-nav {
			background-color: var(--mainColor);
			border-radius: 15px;
			padding: 10px 18px 3px;
			.side-item {
				margin-left: 15px;
				padding-left: 15px;
                position: relative;
                display: inline-block;
                .cart-btn {
                    position: relative;
                    display: inline-block;
                    top: -4px;
                    i {
                        display: inline-block;
                        font-size: 25px;
                        color: var(--whiteColor);
                        border-radius: 50px;
                        text-align: center;
                    }
                    span {
                        position: absolute;
                        right: 0;
                        bottom: 0px;
                        width: 18px;
                        height: 18px;
                        text-align: center;
                        line-height: 20px;
                        border-radius: 50%;
                        background-color: var(--whiteColor);
                        color: var(--mainColor);
                        font-size: 12px;
                        font-weight: 500; 
                    }
                }
                .nav-add-btn {
                    margin-top: 15px;
                    .default-btn {
                        background-color: transparent;
                        border: 1px solid var(--whiteColor);
                    }
                }
			}
            .search-box {
                position: relative;
                display: inline-block;
                i {
                    cursor: pointer;
                    font-size: 18px;
                    color: var(--whiteColor);
                    position: relative;
                    display: inline-block;
                    top: -2px;
                    line-height: 35px;
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    border: 1px solid var(--whiteColor);
                    text-align: center;
                    transition: var(--transition);
                }
                &:hover i {
                    border-color: var(--mainColor);
                    color: var(--mainColor);
                }            
            }
            .user-btn {
                position: relative;
                display: inline-block;
                i {
                    cursor: pointer;
                    font-size: 18px;
                    color: var(--whiteColor);
                    position: relative;
                    display: inline-block;
                    top: -2px;
                    line-height: 35px;
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    border: 1px solid var(--whiteColor);
                    text-align: center;
                    transition: var(--transition);
                }
                &:hover i {
                    border-color: var(--mainColor);
                    color: var(--mainColor);
                }         
            }
		}
	}
}

.sticky-nav {
    top: 0;
    position: fixed;
    animation: 900ms ease-in-out 5s normal none 1 running fadeInDown;
    transition: 0.9s;
    width: 100% !important;
    z-index: 999;
    .main-nav {
        top: 0;
        background-color: var(--titleColor);
        position: fixed;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }
    .nav-two {
        top: 0;
        background-color: var(--whiteColor);
        position: fixed;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }
}
/*=================================
Navbar Area
====================================*/

/*==================================
Search Overlay CSS
====================================*/
.search-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
	.search-layer {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		&:nth-child(1) {
			left: 0;
            background-color: rgba(0, 0, 0, 0.5);
			-webkit-transition: all 0.3s ease-in-out 0s;
			transition: all 0.3s ease-in-out 0s;
		}
		&:nth-child(2) {
			left: 0;
            background-color: rgba(0, 0, 0, 0.5);
			-webkit-transition: all 0.3s ease-in-out 0.3s;
			transition: all 0.3s ease-in-out 0.3s;
		}
		&:nth-child(3) {
			left: 0;
            background-color: rgba(0, 0, 0, 0.5);
			-webkit-transition: all 0.9s ease-in-out 0.6s;
			transition: all 0.9s ease-in-out 0.6s;
		}
	}
	.search-close {
		position: absolute;
		top: 40px;
		right: 40px;
		width: 50px;
		z-index: 2;
		text-align: center;
		cursor: pointer;
		padding: 10px;
		transition: all 0.9s ease-in-out 1.5s;
		opacity: 0;
		visibility: hidden;
		.search-close-line {
			width: 100%;
			height: 3px;
			float: left;
			margin-bottom: 5px;
			background-color: var(--whiteColor);
			transition: all 500ms ease;
			&:nth-child(1) {
				transform: rotate(45deg);
			}
			&:nth-child(2) {
				margin-top: -7px;
				transform: rotate(-45deg);
			}
		}
		&:hover {
			.search-close-line {
				background: var(--mainColor);
				transform: rotate(180deg);
			}
		}
	}
	.search-form {
		transition: all 0.9s ease-in-out 1.4s;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
		z-index: 2;
		max-width: 500px;
        width: 500px;
        padding: 0 15px;
		form {
			position: relative;
			.input-search {
				display: block;
				width: 100%;
				height: 60px;
				border: none;
				border-radius: 30px;
				color: var(--titleColor);
				padding: 3px 0 0 25px;
				&::-webkit-input-placeholder {
					transition: var(--transition);
					letter-spacing: .5px;
					color: var(--titleColor);
				}
				&:-ms-input-placeholder {
					-webkit-transition: var(--transition);
					transition: var(--transition);
					letter-spacing: .5px;
					color: var(--titleColor);
				}
				&::-ms-input-placeholder {
					-webkit-transition: var(--transition);
					transition: var(--transition);
					letter-spacing: .5px;
					color: var(--titleColor);
				}
				&::placeholder {
					-webkit-transition: var(--transition);
					transition: var(--transition);
					letter-spacing: .5px;
					color: var(--titleColor);
				}
				&:focus {
                    border: none;
                    outline: none;
					&::-webkit-input-placeholder {
						color: transparent;
					}
					&:-ms-input-placeholder {
						color: transparent;
					}
					&::-ms-input-placeholder {
						color: transparent;
					}
					&::placeholder {
						color: transparent;
					}
				}
			}
			button {
				position: absolute;
				right: 5px;
				top: 5px;
				width: 50px;
				color: var(--whiteColor);
				height: 50px;
				border-radius: 50%;
				background-color: var(--mainColor);
				-webkit-transition: var(--transition);
				transition: var(--transition);
				border: none;
				font-size: 20px;
				line-height: 45px;
				&:hover {
					background-color: var(--titleColor);
					color: var(--mainColor);
				}
            }
            &:hover {
                border: none;
                outline: none;
            }
		}
	}
}
.search-overlay.search-overlay-active.search-overlay {
	opacity: 1;
	visibility: visible;
	.search-layer {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	.search-close {
		opacity: 1;
		visibility: visible;
	}
	.search-form {
		opacity: 1;
		visibility: visible;
	}
}
/*==================================
Search Overlay CSS End
=================================*/

/*=================================
Home Banner Area
====================================*/
.slider-area {
    position: relative;
    .owl-nav {
        margin-top: 0;
        .owl-prev {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            left: 45px;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;
            &:hover {
                color: var(--whiteColor) !important;
                background-color: transparent !important;
            }
        }
        .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            right: 45px;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;
            &:hover {
                color: var(--whiteColor) !important;
                background-color: transparent !important;
            }
        }
    }
}
.slider-item {
    text-align: center;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.5;
    }
    .slider-content {
        padding-top: 285px;
        padding-bottom: 270px;
        position: relative;
        z-index: 1;
        h1 {
            font-size: 60px;
            color: var(--whiteColor);
            max-width: 790px;
            margin: 0 auto;
            b {
                font-weight: 300;
            }
        }
    }
}
.item-bg1 {
    background-image: url(../img/home-one/home1.jpg);
}
.item-bg2 {
    background-image: url(../img/home-one/home2.jpg);
}
.item-bg3 {
    background-image: url(../img/home-one/home3.jpg);
}
.item-bg4 {
    background-image: url(../img/home-one/home4.jpg);
}

.banner-area {
    background-image: url(../img/home2.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.banner-content {
    padding-top: 150px;
    padding-bottom: 190px;
    p {
        color: var(--mainColor);
        font-family: var(--sub-fontFamily);
    }
    h1 {
        font-size: 90px;
        color: var(--titleColor);
        font-weight: 700;
        max-width: 590px;
        margin-bottom: 20px;
        b {
            font-weight: 300;
        }
    }
    .form-list {
        margin-bottom: 35px;
        ul {
            li {
                color: var(--titleColor) ;
            }
        }
    }
}
.banner-content-ptb {
    padding-top: 160px !important;
    padding-bottom: 230px !important;
}
.banner-area-two {
    background-image: url(../img/home-three/home-three1.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.banner-img {
    position: absolute;
    bottom: 0;
    left: 200px;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: -1;
    img {
        width: 780px;
    }
}
/*=================================
Home Banner Area End
====================================*/

/*=================================
Banner Form Area 
====================================*/
.form-list {
    margin-bottom: 50px;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            margin: 0 10px;
            color: var(--whiteColor);
            text-transform: uppercase;
            font-weight: 500;
            i {
                font-size: 18px;
                color: #e9272a;
                margin-right: 5px;
                opacity: 0;
                transform: var(--transition);
            }
            &:hover {
                color: var(--mainColor);
                i {
                    opacity: 1;
                }
            }
            &.active {
                color: var(--mainColor);
                i {
                    opacity: 1;
                }
            }
        }
    }
}

.banner-form {
    background-color: var(--whiteColor);
    padding: 15px 0 15px 20px;
    box-shadow: 0 0 15px #2a2a2a;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    .form-group {
        position: relative;
        margin-bottom: 0;
        i {
            position: absolute;
            top: 15px;
            left: 0;
            line-height: 1;
            font-size: 18px;
            color: var(--mainColor);
            z-index: 1;
        }
        .form-control {
            height: 50px;
            padding: 10px 20px 10px 25px;
            border: none;
            border-right: 1px solid #ebebeb;
            color: var(--paragraphColor);
            background-color: var(--whiteColor);
            font-weight: 400;
            border-radius: 0;
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
        textarea.form-control {
            height: auto;
        }
    }
    .nice-select {
        height: 50px;
        width: 100%;
        line-height: 32px;
        font-size: 15px;
        margin-bottom: 0px;
        padding-left: 20px;
        border-radius: 50px;
        background-color: var(--mainColor);
        color: var(--whiteColor);
        .list {
            background-color: var(--whiteColor);
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            border-radius: 0;
            margin-top: 0;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            .option {
                -webkit-transition: .6s;
                transition: .6s;
                color: var(--paragraphColor);
                padding-left: 20px;
                padding-right: 20px;
                &:hover {
                    background-color: var(--mainColor) !important;
                    color: #ffffff;
                }
                .selected {
                    background-color: transparent;
                    font-weight: 600;
                }
            }
        }
        &::after {
            height: 8px;
            width: 8px;
            border-color: var(--paragraphColor);
            right: 23px;
        }
    }
}
.banner-form-pl {
    padding: 15px 0 15px 40px;
}
.banner-form-mt {
    position: relative;
    margin-top: -120px;
    z-index: 55;
}
.banner-form .form-group .form-control::-webkit-input-placeholder {
    color: var(--paragraphColor);
}
.banner-form .form-group .form-control:-ms-input-placeholder {
    color: var(--paragraphColor);
} 
.banner-form .form-group .form-control::-ms-input-placeholder {
    color: var(--paragraphColor);
}
.banner-form .form-group .form-control::placeholder {
    color: var(--paragraphColor);
}
/*=================================
Banner Form Area End
====================================*/

/*=================================
Inner Banner
====================================*/
.inner-banner {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.7;
    }
    .inner-title {
        padding-top: 250px;
        padding-bottom: 200px;
        position: relative;
        h3 {
            font-size: 30px;
            color: var(--whiteColor);
            font-weight: 700;
            text-transform: capitalize;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                font-size: 18px;
                color: var(--whiteColor);
                display: inline-block;
                margin-right: 5px;
                text-transform: capitalize;
                &:last-child {
                    margin-right: 0; 
                }
                i {
                    font-size: 26px;
                    color: var(--whiteColor);
                    position: relative;
                    top: 4px;
                }
                a {
                    color: var(--whiteColor);
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
        p {
            color: var(--whiteColor);
            margin-bottom: 0;
        }
    }
    .inner-banner-title {
        padding-top: 250px;
        padding-bottom: 160px;
        position: relative;
        h3 {
            font-size: 50px;
            color: var(--whiteColor);
            font-weight: 700;
            text-transform: capitalize;
        }
        p {
            color: var(--whiteColor);
            margin-bottom: 0;
        }
    }
}
.inner-content {
    padding-top: 250px;
}
.inner-banner-text {
    padding-bottom: 37px;
    h3 {
        font-size: 50px;
        color: var(--whiteColor);
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 20px;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            font-size: 16px;
            color: var(--whiteColor);
            margin-right: 25px;
            &:hover {
                color: var(--mainColor);
            }
            &:hover a {
                color: var(--mainColor);
            }
            &:hover i {
                color: var(--whiteColor);
            }
            i {
                color: var(--mainColor);
                margin-right: 5px;
            }
            a {
                color: var(--whiteColor);
                font-weight: 400;
            }
        }
    }
}

.banner-rating {
    float: right;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50px;
        li {
            display: inline-block;
            font-size: 14px;
            &.rating-star {
                position: relative;
                top: 10px;
                padding-left: 25px;
            }
            span {
                font-size: 14px;
                color: var(--whiteColor);
                display: block;
            }
            i {
                color: #ff8400;
            }
            a {
                width: 70px;
                height: 56px;
                line-height: 56px;
                font-size: 16px;
                font-weight: 700;
                color: var(--whiteColor);
                text-align: center;
                background-color: var(--mainColor);
                border-radius: 50px;
                position: relative;
                top: -1.5px;
            }
        }
    }
}
.banner-bottom {
    padding-top: 35px;
    border-top: 1px solid #ffffff9e;
    padding-bottom: 10px;
}
.banner-tag {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            margin-right: 15px;
            a {
                padding: 7px 18px;
                border-radius: 50px;
                background-color: rgba(255, 255, 255, 0.2);
                border: 1px dashed #ffffff47;
                color: var(--whiteColor);
            }
        }
    }
}
.banner-profile {
    float: right;
    img {
        display: inline-block;
        margin-right: 10px;
        width: 35px !important;
    }
    h3 {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: var(--whiteColor);
    }
    .banner-status {
        margin-left: 25px;
        display: inline-block;
        background-color: var(--mainColor);
        padding: 7px 25px 7px 25px;
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 13% 50%, 0% 0%);
        a {
            display: block;
            h3 {
                font-size: 15px;
                color: var(--whiteColor);
                font-weight: 500;
                margin-bottom: 0;
            }
        } 
    }
}
.banner-list {
    background-color: var(--whiteColor);
    padding: 25px 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative;
    bottom: -40px;
    border-radius: 10px;
    margin-bottom: 40px;
    .list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            font-size: 16px;
            color: #8c8c8c;
            display: inline-block;
            margin-right: 5px;
            text-transform: capitalize;
            &:last-child {
                margin-right: 0; 
            }
            i {
                font-size: 20px;
                color: #8c8c8c;
                position: relative;
                top: 4px;
            }
            a {
                color: #8c8c8c;
                &:hover {
                    color: var(--mainColor);
                }
            }
            &.active {
                color: var(--mainColor);
            }
        }
    }
    .social-link {
        float: right;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-block;
            margin-right: 5px;
            a {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                line-height: 37px;
                text-align: center;
                color: var(--whiteColor);
            }
            &:nth-child(1) a {
                background-color: #3b5998;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(2) a {
                background-color: #55acee;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(3) a {
                background-color: #3f729b;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(4) a {
                background-color: #ff0000;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(5) a {
                background-color: #c80000;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
        }
    }
    p {
        float: right;
        position: relative;
        top: 7px;
        a {
            color: var(--mainColor);
            &:hover {
                color: var(--titleColor);
            }
        }
    }
}
.inner-bg1 {
    background-image: url(../img/inner-banner/inner-banner1.jpg);
}
.inner-bg2 {
    background-image: url(../img/inner-banner/inner-banner2.jpg);
}
.inner-bg3 {
    background-image: url(../img/inner-banner/inner-banner3.jpg);
}
.inner-bg4 {
    background-image: url(../img/inner-banner/inner-banner4.jpg);
}
.inner-bg5 {
    background-image: url(../img/inner-banner/inner-banner5.jpg);
}
.inner-bg6 {
    background-image: url(../img/inner-banner/inner-banner6.jpg);
}
.inner-bg7 {
    background-image: url(../img/inner-banner/inner-banner7.jpg);
}
.inner-bg8 {
    background-image: url(../img/inner-banner/inner-banner8.jpg);
}
.inner-bg9 {
    background-image: url(../img/inner-banner/inner-banner9.jpg);
}
.inner-bg10 {
    background-image: url(../img/inner-banner/inner-banner10.jpg);
}
.inner-bg11 {
    background-image: url(../img/inner-banner/inner-banner11.jpg);
}
/*=================================
Inner Banner End
====================================*/

/*=================================
About Area
====================================*/
.about-list {
    margin-top: 30px;
    margin-bottom: 30px;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: block;
            font-size: 16px;
            color: var(--paragraphColor);
            margin-bottom: 7px;
            i {
                color:var(--mainColor);
                margin-right: 5px;
            }
        }
    }
}
.about-content {
    margin-bottom: 30px;
}
.about-img {
    margin-bottom: 30px;
}
.choose-width {
    position: relative;
    z-index: 1;
    margin-top: 45px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
}
.border-radius-5 {
    border-radius: 5px 5px 0 0;
}
.choose-area-two {
    position: relative;
    top: -100px;
}
.choose-card {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    &:hover i::after {
        right: auto;
        left: -15px;
    }
    i {
        font-size: 55px;
        color:#6d6d6d;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 50px;
            background-color: var(--optionalColor);
            top: -5px;
            right: -15px;
            z-index: -1;
            transition: 0.9s;
        }
    }
    h3 {
        margin-bottom: 10px;
    }
    p {
        max-width: 290px;
        margin: 0 auto;
        font-size: 15px;
    }
}
/*=================================
About Area End
====================================*/

/*=================================
Pricing Area 
====================================*/
.price-card {
    margin-bottom: 30px;
    text-align: center;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
    padding-bottom: 20px;
    .content {
        padding: 20px 20px 10px 20px;
        background-color: var(--mainColor);
        border-radius: 0 0 30px 30px;
        margin-bottom: 20px;
        i {
            font-size: 50px;
            background-color: var(--whiteColor);
            color: #11114b;
            width: 85px;
            height: 85px;
            line-height: 85px;
            border-radius: 50px;
            display: inline-block;
            text-align: center;
            margin-bottom: 15px;
        }
        h3 {
            color: var(--whiteColor);
            padding: 10px 0;
            background-color: #eceae957;
            font-weight: 500;
            margin-bottom: 10px;
        }
        h2 {
           font-size: 45px;
           color: var(--whiteColor);
           sup {
               font-size: 30px;
               font-weight: 400;
            } 
        }
        span {
            font-size: 14px;
            color: var(--whiteColor);
            font-weight: 400;
            position: relative;
            top: -15px;
        }
    }
    ul {
        list-style: none;
        margin: 0 0 30px 0;
        padding: 0;
        li {
            display: block;
            color: var(--paragraphColor);
            padding: 10px 0;
            border-bottom: 1px solid #ededed;
        }
    }
}
/*=================================
Pricing Area End
====================================*/

/*=================================
Team Area
====================================*/
.team-area {
    background-color: #fdf8f5;
}
.team-card {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
    margin-bottom: 30px;
    transition: 0.7s;
    &:hover {
        transform: translateY(-10px);
    }
    a {
        display: block;
        img {
            max-width: 100%;
            border-radius: 5px 5px 0 0;
        }
    }
    .content {
        padding: 30px 25px;
        position: relative;
        &::before {
            content: '';
            top: 30px;
            left: 0;
            position: absolute;
            height: 45px;
            width: 7px;
            border-radius: 2px;
            background-color: var(--mainColor);
        }
        h3 {
            margin-bottom: 0;
            font-weight: 500;
            color: var(--titleColor);
            a {
                color: var(--titleColor);
                display: block;
            }
        }
        span {
            font-size: 15px;
            font-weight: 500;
            color: var(--titleColor);
        }
        p {
            margin-top: 10px;
        }
        .social-link {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: inline-block;
                margin-right: 5px;
                a {
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    line-height: 37px;
                    text-align: center;
                    color: var(--whiteColor);
                }
                &:nth-child(1) a {
                    background-color: #3b5998;
                    &:hover {
                        background-color: var(--mainColor);
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(2) a {
                    background-color: #55acee;
                    &:hover {
                        background-color: var(--mainColor);
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(3) a {
                    background-color: #3f729b;
                    &:hover {
                        background-color: var(--mainColor);
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(4) a {
                    background-color: #ff0000;
                    &:hover {
                        background-color: var(--mainColor);
                        animation: tada 1s linear;
                    }
                }
            }
        }
    }
}
/*=================================
Team Area End
====================================*/

/*=================================
Category Area
====================================*/
.category-bg {
    margin-top: 45px;
    background-color: var(--whiteColor);
    box-shadow: 0px -65px 50px 7px rgba(246, 246, 246, 0.7);
    padding: 70px 70px 0 70px;
}
.category-card {
    margin-bottom: 30px;
    text-align: center;
    &:hover {
        a i {
            color: var(--mainColor);
        }
    }
    a {
        display: block;
        i {
            font-size: 50px;
            color:#6a6a6a;
            margin-bottom: 15px;
            transition: var(--transition);
        }
    }
   
    a {
       
        h3 {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    p {
        margin: 0 auto;
        max-width: 320px;
    }
}
.category-box {
    margin-bottom: 30px;
    text-align: center;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 25px;
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 100%;
        opacity: 0;
        background-color: var(--mainColor);
        transition: 0.7s;
        z-index: -1;
    }
    &:hover {
        &::before {
            opacity: 1;
            width: 100%;
        }
        a {
            i {
                color: var(--whiteColor);
            }
        }
       
        a {
            h3 {
                color: var(--whiteColor);
            }
        }
    }
    a {
        display: block;
        i {
            font-size: 50px;
            color: var(--mainColor);
            margin-bottom: 15px;
            transition: var(--transition);
        }
    }
    a {
        display: block;
        h3 {
            margin-bottom: 0;
            color: var(--mainColor);
        }
    }
}
.category-box-area {
    background-color: var(--whiteColor);
    padding-top: 50px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ededed;
}
.category-box-card {
    margin-bottom: 30px;
    text-align: center;
    &:hover {
        a {
            i {
                &::after {
                    left: auto;
                    right: 10px; 
                }
            }
        }
        h3 {
            a {
                color: var(--mainColor);
            }
        }
    }
    a {
        display: block;
        position: relative;
        z-index: 1;
        i {
            font-size: 50px;
            color: var(--titleColor);
            margin-bottom: 15px;
            transition: var(--transition);
            position: relative;
            z-index: 1;
            &::after {
                content: '';
                position: absolute;
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background-color: var(--optionalColor);
                top: 0;
                left: 10px;
                z-index: -1;
                transition: 0.9s;
            }
        }
    }
    h3 {
        margin-bottom: 0;
        a {
            display: block;
            color: var(--titleColor);
            transition: var(--transition);
        }
    }
}
/*=================================
Category Area End
====================================*/

/*=================================
Place List Area 
====================================*/
.place-list-area {
    position: relative;
    .owl-dots {
        margin-top: 0px !important;
        margin-bottom: 30px;
        .owl-dot {
            span {
                background-color: var(--mainColor) !important;
                width: 7px !important;
                height: 7px !important;
                transition: 0.7s;
            }
            &.active span {
                background-color: var(--mainColor) !important;
                width: 12px !important;
                height: 12px !important;
            }
            &:hover span {
                background-color: var(--mainColor) !important;
                width: 12px !important;
                height: 12px !important;
            }
        }
    }
}
.place-list-two {
    position: relative;
    overflow: hidden;
    .section-title {
        max-width: 1320px;
        margin-left: auto;
    }
    .owl-nav {
        margin-top: 0;
        .owl-prev {
            position: absolute;
            top: -50px;
            right: 7%;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;;
            &:hover {
                color: var(--titleColor) !important;
                background-color: transparent !important;
            }
        }
        .owl-next {
            position: absolute;
            top: -50px;
            right: 5%;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;
            &:hover {
                color: var(--titleColor) !important;
                background-color: transparent !important;
            }
        }
    }

}
.place-list-three {
    position: relative;
    overflow: hidden;
    .section-title {
        max-width: 1500px;
        margin-left: auto;
    }
    .owl-nav {
        margin-top: 0;
        .owl-prev {
            position: absolute;
            top: -50px;
            right: 17%;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;;
            &:hover {
                color: var(--titleColor) !important;
                background-color: transparent !important;
            }
        }
        .owl-next {
            position: absolute;
            top: -50px;
            right: 15%;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;
            &:hover {
                color: var(--titleColor) !important;
                background-color: transparent !important;
            }
        }
    }

}
.place-slider-three {
    left: calc((100% - 1170px) /2);
    position: relative;
}
.place-list-item {
    margin-bottom: 30px;
    .images {
        position: relative;
        .images-list {
            display: block;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                width: 100%;
                height: 100%;
                background-color: #000000;
                opacity: 0.5;
            }
            img {
                width: 100%;
                border-radius: 5px 0 5px 0;
            }
        }
        .place-profile {
            position: absolute;
            top: 30px;
            left: 15px;
            img {
                display: inline-block;
                margin-right: 10px;
                width: 35px !important;
            }
            h3 {
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                color: var(--whiteColor);
            }
        }
        .place-status {
            position: absolute;
            top: 30px;
            right: -10px;
            padding: 5px 15px 5px 25px;
            clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 13% 50%, 0% 0%);
            a {
                display: block;
                h3 {
                    font-size: 13px;
                    color: var(--whiteColor);
                    font-weight: 500;
                    margin-bottom: 0;
                }
            } 
        }
        .place-tag {
            position: absolute;
            bottom: 20px;
            left: 15px;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: inline-block;
                li {
                    display: inline-block;
                    a {
                        text-align: center;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 14px;
                        color: var(--mainColor);
                        background-color: var(--whiteColor);
                        border-radius: 50px;
                        &:hover {
                            background-color: var(--mainColor);
                            color: var(--whiteColor);
                        }
                    }
                }
            }
            span {
                a {
                    display: inline-block;
                    color: var(--whiteColor);
                }
                display: inline-block;
                margin-left: 25px;
            }
            h3 {
                a {
                    display: inline-block;
                    color: var(--whiteColor);
                }
                display: inline-block;
                margin-left: 25px;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    .content {
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
        padding: 30px 30px 20px 30px;
        &:hover a {
            h3 {
                color: var(--mainColor);
            }
        }
        a {
            display: block;
            h3 {
                margin-bottom: 15px;
                transition: var(--transition);
            }
        }

        p {
            i {
                position: absolute;
                font-size: 18px;
                color: var(--mainColor);
                left: -30px;
            }
            margin-left: 30px;
            margin-bottom: 0;
            padding-bottom: 15px;
            border-bottom: 1px dashed #e8e8e8;
            position: relative;
        }
        .place-rating {
            padding: 15px 0;
            list-style: none;
            margin: 0;
            li {
                display: inline-block;
                font-size: 14px;
                margin-right: 15px;
                i {
                    color: var(--mainColor);
                }
                .icon-color {
                    color: var(--optionalColor) !important;
                }
                a {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    font-size: 14px;
                    color: var(--mainColor);
                    text-align: center;
                    border: 1px solid  var(--mainColor);
                    border-radius: 50px;
                }
            }
        }
    }
}
.listing-section-right {
    margin-bottom: 35px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding-bottom: 30px;
    .title {
        font-size: 20px;
        color: var(--titleColor);
        margin-bottom: 30px;
        border-bottom: 1px solid var(--mainColor);
        padding-bottom: 15px;
        padding-top: 15px;
        font-weight: 400;
        padding-left: 35px;
        i {
            color: var(--mainColor);
            margin-right: 10px;
        }
    }
    .facilities-list {
        padding: 0 30px 30px;
        border-bottom: 1px solid #ececec;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: block;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                .agree-label  {
                    label {
                        font-size: 15px;
                        font-weight: 400;
                        color: #636363;
                        margin-left: 5px;
                    }
                }
            }
        }
        .facilities-title {
            font-size: 17px;
            text-align: center;
            border: 1px dashed var(--mainColor);
            padding: 4px 0;
            font-weight: 400;
            color: #7f7f7f;
            background-color:#fff6f2;
            margin-bottom: 20px;
        }
    }
    .default-btn {
        margin-top: 20px;
    }
}
.listing-right-form {
    margin-left: 30px;
    margin-right: 30px;
    .form-group {
        margin-bottom: 25px;
        position: relative;
        label {
            color: var(--paragraphColor);
            position: relative;
            top: 12px;
        }
        i {
            position: absolute;
            top: 18px;
            left: 15px;
            line-height: 1.1;
            font-size: 14px;
            color: var(--mainColor);
            z-index: 1;
        }
        .form-control {
            height: 50px;
            color: #7f7f7f;
            border: 1px solid #e8e8e8;
            background-color: #ffffff;
            border-radius: 0;
            font-size: 14px;
            padding: 10px 20px 10px 40px;
            width: 100%;
            &:focus {
                outline: none;
                border-color: #ffc0a7;
                box-shadow: none;
            }
        }
    }
    .nice-select {
        height: 50px;
        width: 100%;
        line-height: 32px;
        font-size: 15px;
        margin-bottom: 25px;
        padding-left: 20px;
        border-radius: 50px;
        background-color: var(--mainColor);
        color: var(--whiteColor);
        .list {
            background-color: var(--whiteColor);
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            border-radius: 0;
            margin-top: 0;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            .option {
                -webkit-transition: .6s;
                transition: .6s;
                color: var(--paragraphColor);
                padding-left: 20px;
                padding-right: 20px;
                &:hover {
                    background-color: var(--mainColor) !important;
                    color: #ffffff;
                }
                .selected {
                    background-color: transparent;
                    font-weight: 600;
                }
            }
        }
        &::after {
            height: 8px;
            width: 8px;
            border-color: var(--mainColor);
            right: 23px;
        }
    }
}
.listing-widget-into {
    .listing-widget-menu {
        list-style: none;
        margin-top: 7px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        padding: 0;
        float: right;
        li {
            display: inline-block;
            a {
                text-align: center;
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 16px;
                color: #a3a3a3;
                border: 1px solid #a3a3a3;
                border-radius: 50px;
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                }
                &.active {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                }
            }
        }
    }
    .default-btn {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .listing-right-form { 
        margin-left: 0;
    }
}
/*=================================
Place List Area End
====================================*/

/*=================================
Process Area 
====================================*/
.process-bg {
    background-color: #f5f5f5;
}
.process-bg2 {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../img/process-bg.jpg);
    margin-bottom: 50px;
}
.process-into {
    bottom: -50px;
    position: relative;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    padding-top: 60px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}
.process-into-2 {
    background: none !important;
    box-shadow: none !important;
    bottom: 0 !important;
    margin-bottom: 40px;
    margin-top: 40px;
}
.process-line {
    position: absolute;
    top: 50%;
    left: 70px;
    right: 0;
    margin: 0 auto;
    text-align: center;
}
.process-line-2 {
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}
.process-card {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    &:hover i::after{
       right: 10px;
       left: auto;
    }
    &:hover .process-number {
        color: var(--mainColor);
    }
    i {
        font-size: 55px;
        color: var(--titleColor);
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        transition: 0.9s;
        &::after {
            content: '';
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background-color: var(--optionalColor);
            top: 25px;
            left: 10px;
            z-index: -1;
            transition: 0.9s;
        }
        &:hover::after {
            right: 10px;
            left: auto;
        }
    }
    .icon-bg {
        width: 95px;
        height: 95px;
        background-color: var(--whiteColor);
        border-radius: 50px;
        line-height: 95px;
        display: inline-block;
    }
    h3 {
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 0;
    }
    .process-number {
        position: absolute;
        top: 10px;
        right: 0;
        font-size: 40px;
        color: var(--paragraphColor);
        font-family: var(--sub-fontFamily);
        transition: var(--transition);
        &.active {
            color: var(--mainColor);
        }
    }
}
.process-item {
    margin-bottom: 30px;
    position: relative;
    .process-item-number {
        text-align: center;
        position: absolute;
        font-size: 40px;
        color: var(--paragraphColor);
        font-family: var(--sub-fontFamily);
        transition: var(--transition);
        &.active {
            color: var(--mainColor);
        }
    }
    .number1 {
        position: absolute;
        left: 120px;
        top: -75px;
    }
    .number2 {
        position: absolute;
        left: 130px;
        top: -60px;
    }
    .number3 {
        position: absolute;
        left: 165px;
        top: -88px;
    }
    i {
        font-size: 55px;
        color: var(--titleColor);
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        transition: 0.9s;
        display: inline-block;
    }
    .content {
        display: inline-block;
        position: relative;
        margin-top: -80px;
        margin-left: 70px;
        h3 {
            margin-bottom: 15px;
            font-weight: 500;
            display: inline-block;
        }
        p {
            margin-bottom: 0;
            display: inline-block;
        }
    }
}
.process-into {
    .col-lg-4 {
        &:nth-child(2){
            .process-item {
                margin-top: 50px;
            }
        }
    }
}
.process-into {
    .col-lg-4 {
        &:nth-child(3){
            .process-item {
                margin-top: 20px;
            }
        }
    }
}
/*=================================
Process Area End
====================================*/

/*=================================
City Area
====================================*/
.city-item {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    .city-img {
        display: block;
        border-radius: 5px;
        img {
            width: 100%;
            height: 410px;
            border-radius: 5px;
        }
    }
    .city-content {
        position: absolute;
        top: 40px;
        left: 30px;
        i {
            font-size: 24px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50px;
            display: inline-block;
            background-color: var(--whiteColor);
            color: var(--mainColor);
            text-align: center;
            position: relative;
            top: -10px;
            margin-right: 10px;
        }
        .city-text {
            display: inline-block;
            a {
                h3 {
                    color: var(--whiteColor);
                    margin-bottom: 5px;
                    font-size: 18px;
                }
            }
           
            p {
                color: var(--whiteColor);
                margin-bottom: 0;
                font-size: 15px;
            }
        }
    }
    .city-location {
        position: absolute;
        bottom: 30px;
        right: 60px;
        font-size: 30px;
        font-family: var(--sub-fontFamily);
        color: var(--whiteColor);
    }
}

.city-btn {
    margin-top: 20px;
    float: right;
}
.city-card {
    margin-bottom: 30px;
    a {
        display: block;
        img {
            height: 365px;
            width: 100%;
            transition: 0.7s;
        }
    }
    &:hover a img {
        filter: brightness(0.5);
    }
    &:hover .content a h3 {
        color: var(--mainColor);
    }
    .content {
        padding-top: 25px;
        a {
            display: block;
            h3 {
                font-size: 22px;
                font-weight: 400;
                margin-bottom: 5px; 
                transition: 0.7s;
            }
        }
        span {
            font-size: 25px;
            color: var(--mainColor);
            font-family: var(--sub-fontFamily);
        }
    }
}
/*=================================
City Area End
====================================*/

/*=================================
Video Area 
====================================*/
.video-area-bg {
    background-image: url(../img/video-area-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5;
    }
}
.video-ptb {
    padding-top: 124px !important;
    padding-bottom: 124px !important;
}
.video-content {
    padding-top: 100px;
    padding-bottom: 200px;
    text-align: center;
    position: relative;
    h2 {
        font-size: 38px;
        color: var(--whiteColor);
    }
    .play-btn {
        margin-top: 50px;
        width: 85px;
        height: 85px;
        line-height: 95px;
        border-radius: 50px;
        font-size: 45px;
        background-color: var(--mainColor);
        color: var(--whiteColor);
        position: relative; 
        &:hover {
            background-color: var(--whiteColor);
            color: var(--mainColor);
        }
        &:hover::before {
            border-color: var(--whiteColor);
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid var(--mainColor);
            animation: ripple 1s linear 2s infinite;
        }
    }
}
.video-area-two {
    background-image: url(../img/video-area-bg2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5;
    }
}
.video-option {
    padding-top: 100px;
    padding-bottom: 250px;
    position: relative;
    h2 {
        font-size: 38px;
        color: var(--whiteColor);
    }
    .video-btn {
        margin-left: 200px;
        .play-video {
            margin-top: 50px;
            width: 85px;
            text-align: center;
            height: 85px;
            line-height: 85px;
            border-radius: 50px;
            font-size: 45px;
            background-color: var(--whiteColor);
            color: var(--mainColor);
            position: relative; 
            &:hover {
                background-color: var(--mainColor);
                color: var(--whiteColor);
            }
            &:hover::after {
                border-color: var(--mainColor);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid var(--whiteColor);
                animation: ripple 1s linear 2s infinite;
            }
        }
        &:hover span{
            color: var(--mainColor);
        }
        span {
            font-size: 35px ;
            color: var(--whiteColor);
            font-family: var(--sub-fontFamily);
            margin-left: 30px;
            i {
                font-weight: 500;
                font-size: 24px;
            }
        }
    }
}
/*=================================
Video Area End
====================================*/

/*=================================
Counter Area 
====================================*/
.counter-area {
    position: relative;
    margin-top: -70px;
}
.counter-area-three {
    background-color: #150001a8;
    padding: 40px 0;
    position: relative;
    margin-top: -160px;
}
.counter-bg {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.3);
    padding: 40px 0;
    position: relative;
    z-index: 5;
}
.counter-bg2 {
    position: relative;
    padding: 40px 0;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(#f14300, #ababab);
        opacity: 0.2;
    }
}
.single-counter {
    text-align: center;
    position: relative;
    h3 {
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 400;
    }
    span {
        color: var(--paragraphColor);
        margin-bottom: 0;
        font-weight: 500;
    }
}
/*=================================
Counter Area End
====================================*/

/*=================================
Place Area
====================================*/
.place-btn {
    float: right;
    margin-bottom: 45px;
}
.place-card {
    position: relative;
    margin-bottom: 30px;
    transition: 0.7s;
    &:hover {
        transform: translateY(-10px);
        .status-tag {
            background-color: var(--whiteColor) !important;
            h3 {
                color: var(--mainColor);
            }
        }
    }
    .place-images {
        display: block;
        position: relative;
        border-radius: 5px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.5;
            border-radius: 5px;
        }
        img {
          max-width: 100%;
          border-radius: 5px;
        }
    }
   
    .rating {
        position: absolute;
        top: 40px;
        left: 35px;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: inline-block;
                font-size: 14px;
                margin-right: 15px;
                span {
                    font-size: 14px;
                    color: var(--whiteColor);
                    display: block;
                }
                i {
                    color: #ff8400;
                }
                .i-color {
                    color: #a85802 !important;
                }
                a {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    font-size: 14px;
                    color: var(--whiteColor);
                    text-align: center;
                    border: 1px solid  var(--whiteColor);
                    border-radius: 50px;
                    position: relative;
                    top: -10px;
                }
            }
        }
    }
    .status-tag {
        position: absolute;
        top: 40px;
        right: -10px;
        padding: 5px 15px 5px 25px;
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 13% 50%, 0% 0%);
        transition: 0.7s;
        h3 {
            font-size: 13px;
            color: var(--whiteColor);
            font-weight: 500;
            margin-bottom: 0;
            transition: 0.7s;
        }
    }
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 35px 20px 30px 20px;
        .content-profile {
            margin-bottom: 10px;
            img {
                display: inline-block;
                margin-right: 10px;
            }
            h3 {
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                color: var(--whiteColor);
            }
        }
        span {
            color: var(--whiteColor);
            font-size: 14px;
            margin-bottom: 10px;
            i {
                color: var(--mainColor);
                font-size: 18px;
                margin-right: 5px;
            }
        }
        h3 {
            color: var(--whiteColor);
            margin-bottom: 10px;
            margin-top: 10px;
            font-weight: 400;
            font-size: 18px;
        }
        p {
            color: var(--whiteColor);
            margin-bottom: 0;
            padding-bottom: 15px;
            border-bottom: 1px dashed #615649;
            font-size: 15px;
        }
        .content-tag {
            padding-top: 15px;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: inline-block;
                li {
                    display: inline-block;
                    a {
                        text-align: center;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 14px;
                        color: var(--whiteColor);
                        background-color: rgba(255, 255, 255,0.31%);
                        border-radius: 50px;
                    }
                    &:hover a {
                        background-color: var(--whiteColor);
                        color: var(--mainColor);
                    }
                }
            }
            h3 {
                margin-left: 25px;
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                a {
                    display: inline-block;
                    color: var(--mainColor); 
                }
                &:hover a{
                    color: var(--whiteColor);
                }
            }
            .price {
                float: right;
            }
        }
    }
    .content-bg {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: -1;
            height: 0;
            background-color: var(--whiteColor);
            transition: 0.7s;
        }
    }
    &:hover {
        .content-bg {
            z-index: 1;
            &::before {
                height: 100%;
            }
            .content-profile {
                h3 {
                    color: var(--titleColor);
                }
            }
            span {
               color: var(--titleColor);
            }
            h3 {
                color: var(--titleColor);
            }
            p {
                color: var(--titleColor);
            }
            .content-tag {
                padding-top: 15px;
                ul {
                    li {
                        display: inline-block;
                        a {
                            background-color: var(--whiteColor);
                            color: var(--mainColor);
                            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
                        }
                        &:hover a {
                            background-color: var(--titleColor);
                            color: var(--whiteColor);
                        }
                    }
                }
                h3 {
                    a {
                        display: inline-block;
                        color: var(--mainColor); 
                    }
                    &:hover a{
                        color: var(--titleColor);
                    }
                }
                .price {
                    float: right;
                }
            }
        }
    }
    &.active {
        .content-bg {
            z-index: 1;
            &::before {
                height: 100%;
            }
            .content-profile {
                h3 {
                    color: var(--titleColor);
                }
            }
            span {
               color: var(--titleColor);
            }
            h3 {
                color: var(--titleColor);
            }
            p {
                color: var(--titleColor);
            }
            .content-tag {
                padding-top: 15px;
                ul {
                    li {
                        display: inline-block;
                        a {
                            background-color: var(--whiteColor);
                            color: var(--mainColor);
                            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
                        }
                        &:hover a {
                            background-color: var(--mainColor);
                            color: var(--whiteColor);
                        }
                    }
                }
                h3 {
                    a {
                        display: inline-block;
                        color: var(--mainColor); 
                    }
                    &:hover a{
                        color: var(--titleColor);
                    }
                }
                .price {
                    float: right;
                }
            }
        }
    }
}
/*=================================
Place Area End
====================================*/

/*=================================
Listing Details Area 
====================================*/
.listing-details-wrap {
    margin-bottom: 30px;
} 
.listing-category {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 30px;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            margin-right: 15px;
            a {
                padding: 7px 18px;
                border-radius: 50px;
                background-color: #fff6f2;
                border: 1px dashed var(--mainColor);
                color: var(--mainColor);
            }
            &:hover a {
                background-color: var(--mainColor);
                color: var(--whiteColor);
            }
        }
    }
    h3 {
        float: right;
        color: #555555;
        font-size: 16px;
        font-weight: 400;
        position: relative;
        margin-top: -30px;
        text-decoration: underline;
        i {
            color: var(--mainColor);
            margin-right: 7px;
        }
    }
}
.listing-widget {
    margin-bottom: 35px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    .title {
        font-size: 20px;
        color: var(--titleColor);
        margin-bottom: 30px;
        border-bottom: 1px solid #c8ccd0;
        padding-bottom: 15px;
        padding-top: 15px;
        font-weight: 400;
        padding-left: 35px;
    }
    &.listing-widget-desc {
        padding-bottom: 35px;
        p {
            margin-bottom: 20px;
            font-weight: 400;
            padding: 0 35px;
            font-size: 15px;
        }
        .default-btn {
            margin-left: 35px;
            margin-top: 10px;
            margin-bottom: 40px;
        }
    }
}
.listing-features {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    margin: 0 35px;
    border: 1px dashed var(--mainColor);
    background-color: #fff6f2;
    .features-title {
        text-align: center;
        font-size: 17px;
        color: var(--mainColor);
        width: 185px;
        height: 45px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        line-height: 45px;
        border-left: 1px dashed var(--mainColor);
        border-right: 1px dashed var(--mainColor);
        border-bottom: 1px dashed var(--mainColor);
    }
    .features-card {
        text-align: center;
        margin-bottom: 30px;
        i {
            width: 50px;
            height: 50px;
            background-color: var(--whiteColor);
            border-radius: 50px;
            font-size: 24px;
            color: var(--mainColor);
            line-height: 50px;
            text-align: center;
            display: inline-block;
            margin-bottom: 10px;
        }
        h3 {
            font-size: 15px;
            color: var(--mainColor);
            margin-bottom: 0;
        }
    }
}
.gallery-slider-area {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    position: relative;
    .owl-nav {
        margin-top: 0;
        .owl-prev {
            position: absolute;
            top: 35%;
            transform: translateY(-16px);
            left: 5px;
            color: var(--whiteColor) !important;
            font-size: 24px !important;
            width: 70px !important;
            height: 55px !important;
            line-height: 55px !important;
            background-color: var(--mainColor) !important;
            border-radius: 50px;
            &:hover {
                color: var(--whiteColor) !important;
                background-color: var(--titleColor) !important;
            }
        }
        .owl-next {
            position: absolute;
            top: 35%;
            transform: translateY(-16px);
            right: 5px;
            color: var(--whiteColor) !important;
            font-size: 24px !important;
            width: 70px !important;
            height: 55px !important;
            line-height: 55px !important;
            background-color: var(--mainColor) !important;
            border-radius: 50px;
            &:hover {
                color: var(--whiteColor) !important;
                background-color: var(--titleColor) !important;
            }
        }
    }
}
.listing-map iframe {
    width: 100%;
    height: 380px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}
.listing-widget-review {
    padding: 0 30px 60px 30px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: -30px;
        margin: 0;
        left: 0;
        right: 0;
        background-color: #fef0eb;
    }
    ul {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #162c44;
        border-radius: 50px;
        width: 200px;
        li {
            display: inline-block;
            font-size: 14px;
            &.rating-star {
                position: relative;
                top: 10px;
                padding-left: 35px;
            }
            span {
                font-size: 14px;
                color: var(--whiteColor);
                display: block;
            }
            i {
                color: #ff8400;
            }
            a {
                width: 70px;
                height: 56px;
                line-height: 56px;
                font-size: 16px;
                font-weight: 700;
                color: var(--whiteColor);
                text-align: center;
                background-color: var(--mainColor);
                border-radius: 50px;
                position: relative;
                top: -1.5px;
            }
        }
    }
}
.progressbar-area {
    padding: 0 30px 10px 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid #ebebeb;
    .single-progressbar {
        margin-bottom: 30px;
        h3 {
            font-size: 18px;
            color: var(--titleColor);
            text-transform: uppercase;
            font-weight: 400;
            margin-bottom: 15px;
        }
        .progressbar-count {
            position: relative;
            width: 100%;
            height: 5px;
            border-radius: 0;
            background-color: var(--mainColor);
            .progressbar-number {
                position: absolute;
                font-size: 16px;
                font-weight: 400;
                color: var(--titleColor);
            }
            .progressbar-circle {
                width: 13px;
                height: 13px;
                background-color: var(--mainColor);
                border-radius: 50px;
                position: absolute;
            }
            .pn-1 {
                top: -40px;
                right: 35%;
            }
            .pc-1 {
                top: -5px;
                right: 36%;
            }
            .pn-2 {
                top: -40px;
                right: 25%;
            }
            .pc-2 {
                top: -5px;
                right: 26%;
            }
            .pn-3 {
                top: -40px;
                right: 35%;
            }
            .pc-3 {
                top: -5px;
                right: 35%;
            }
            .pn-4 {
                top: -40px;
                right: 38%;
            }
            .pc-4 {
                top: -5px;
                right: 38%;
            }
        }
    }
}
.listing-comment {
    padding-bottom: 40px;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            position: relative;
            padding: 30px;
            padding-left: 150px;
            border-bottom: 1px solid #eeeeee;
            &:last-child {
                border: none;
            }
            img {
                border-radius: 50%;
                position: absolute;
                top: 35px;
                left: 30px;
            }
            .content {
                background-color: #fff6f2;
                padding: 35px;
                border: 1px dashed var(--mainColor);
                h3 {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 500;
                }
                span {
                    margin-bottom: 10px;
                    display: block;
                }
                p {
                    margin-bottom: 0;
                    font-size: 15px;
                }
                .comment-rating {
                    position: absolute;
                    top: 60px;
                    right: 60px;
                    i {
                        color: #ff8400;
                        display: inline-block;
                    }
                    span {
                        margin-left: 10px;
                        display: inline-block;
                        width: 42px;
                        height: 35px;
                        line-height: 35px;
                        font-size: 12px;
                        font-weight: 700;
                        color: var(--whiteColor);
                        text-align: center;
                        background-color: var(--mainColor);
                        border-radius: 50px;
                        position: relative;
                        top: -3px;
                    }
                   
                    &:hover {
                        color: var(--titleColor);
                    }
                }
                a {
                    color: var(--mainColor);
                    font-weight: 600;
                    margin-top: 15px;
                    &:hover {
                        color: var(--titleColor);
                    }
                }
                .view-list {
                    position: absolute;
                    right: 60px;
                    bottom: 60px;
                    font-size: 12px;
                    color: var(--mainColor);
                    padding: 4px 7px;
                    border-radius: 50px;
                    border: 1px dashed var(--mainColor);
                    width: 148px;
                    text-align: center;
                }
            }
        }
    }
}
.progressbar-area-title {
    .title {
        margin-bottom: 0 !important;
    }
}
.progressbar-bg {
    padding: 30px !important;
    position: relative;
    background-color: #fef8f5;
}
.listing-widget-contact {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    .contact-form {
        padding: 50px 10px;
    }
}
.listing-contact-title {
    border-bottom: 1px solid #c8ccd0;
    padding-bottom: 15px;
    padding-top: 15px;
    font-weight: 400;
    padding-left: 35px;
    .listing-widget-mail {
        i {
            font-size: 50px;
            color: #9fa8b2;
            display: inline-block;
        }
        .content {
            display: inline-block;
            margin-left: 15px;
            h3 {
                font-size: 14px;
                color: #6a6a6a;
                margin-bottom: 0;
            }
            p {
                font-size: 14px;
                color: var(--titleColor);
            }
        }
    }
}
.listing-contact-review {
    float: right;
    padding-right: 30px;
    padding-top: 10px;
    ul {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #162c44;
        border-radius: 50px;
        width: 200px;
        li {
            display: inline-block;
            font-size: 14px;
            &.rating-star {
                position: relative;
                top: 10px;
                padding-left: 35px;
            }
            span {
                font-size: 14px;
                color: var(--whiteColor);
                display: block;
            }
            i {
                color: #ff8400;
            }
            a {
                width: 70px;
                height: 56px;
                line-height: 56px;
                font-size: 16px;
                font-weight: 700;
                color: var(--whiteColor);
                text-align: center;
                background-color: var(--mainColor);
                border-radius: 50px;
                position: relative;
                top: -1.5px;
            }
        }
    }
}
.listing-widget-side {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding-bottom: 30px;
    margin-bottom: 30px;
    .title {
        font-size: 20px;
        color: var(--titleColor);
        margin-bottom: 30px;
        border-bottom: 1px solid #c8ccd0;
        padding-bottom: 15px;
        padding-top: 15px;
        font-weight: 400;
        padding-left: 30px;
    }
    .social-link {
        margin: 0;
        padding: 10px 30px 0 30px;
        list-style: none;
        li {
            display: inline-block;
            margin-right: 5px;
            a {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                line-height: 37px;
                text-align: center;
                color: var(--whiteColor);
            }
            &:nth-child(1) a {
                background-color: #3b5998;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(2) a {
                background-color: #55acee;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(3) a {
                background-color: #3f729b;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(4) a {
                background-color: #ff0000;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(5) a {
                background-color: #c80000;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
        }
    }
    .listing-side-tag {
        list-style: none;
        margin: 0 10px;
        padding: 0;
        li {
            display: inline-block;
            padding: 0 5px;
            transition: 0.7s;
            color: #777777;
            font-size: 18px;
            a {
                color: #777777;
            }
            &:hover a {
                color: var(--mainColor);
            }
            &.active a {
                color: var(--mainColor);
            }
        }
    }
}
.listing-Booking-form {
    padding: 0 30px;
    .form-group {
        margin-bottom: 25px;
        position: relative;
        i {
            position: absolute;
            top: 14px;
            left: 15px;
            line-height: 1;
            font-size: 18px;
            color: var(--mainColor);
            z-index: 1;
        }
        .form-control {
            height: 50px;
            color: #7f7f7f;
            border: 1px solid #e8e8e8;
            background-color: #ffffff;
            border-radius: 0;
            font-size: 14px;
            padding: 10px 20px 10px 40px;
            width: 100%;
            &:focus {
                outline: none;
                border-color: #ffc0a7;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
        }
    }
    .add-counter {
        padding: 10px 0;
        position: relative;
        h3 {
            display: inline-block;
            font-size: 16px;
            color: var(--titleColor);
            padding-left: 20px;
        }
    }
    .form-input-counter {
        max-width: 60px;
        min-width: 60px;
        height: 60px;
        text-align: center;
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 7px;
        .plus-btn {
            position: absolute;
            top: 0;
            right: 0;
            background-color: transparent;
            cursor: pointer;
            color: var(--titleColor);
            width: 20px;
            height: 22px;
            border: 1px solid #eeeeee;
            transition: 0.5s;
            &:hover {
                color: var(--mainColor) !important;
            }
        }
        .minus-btn {
            position: absolute;
            top: 22px;
            right: 0;
            background-color: transparent;
            cursor: pointer;
            color: var(--titleColor);
            width: 20px;
            height: 22px;
            border: 1px solid #eeeeee;
            transition: 0.5s;
            &:hover {
                color: var(--mainColor) !important;
            }
        }
        input {
            height: 45px;
            color: var(--titleColor);
            outline: 0;
            display: block;
            border: none;
            background-color: #f8f8f8;
            text-align: center;
            width: 100%;
            font-size: 17px;
            font-weight: 600;
            padding-right: 20px;
        }
    }
    .price-rate {
        font-size: 16px;
        padding: 15px 0;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        margin-bottom: 25px;
        span {
            color: var(--mainColor);
            float: right;
        }
    }
}
.listing-widget-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: block;
        padding: 10px ;
        margin: 7px 30px;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        font-size: 15px;
        color: #162c44;
        span {
            float: right;
            color: var(--paragraphColor);
            a {
                color: var(--paragraphColor);
                &:hover {
                    color: var(--mainColor);
                }
            }
        }
    }
}
.price-title {
    font-size: 16px;
    padding: 0px 30px;
    span {
        color: var(--mainColor);
        float: right;
    }
}
.listing-side-host {
    text-align: center;
    margin-bottom: 30px;
    img {
        margin-bottom: 20px;
    }
    h3 {
        font-size: 17px;
        margin-bottom: 0px;
    }
    span {
        font-size: 12px;
        color: var(--mainColor);
    }
}
.side-host-list {
    list-style: none;
    margin: 0 30px;
    padding: 10px 0;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    li {
        display: inline-block;
        margin-right: 10px;
       
        a {
            width: 40px;
            height: 40px;
            line-height: 40px;
            background-color: var(--mainColor);
            color: var(--whiteColor);
            font-size: 20px;
            text-align: center;
            border-radius: 50px;
            i {
                color: var(--whiteColor);
            }
        }
        span {
            color: var(--mainColor);
            top: 0px;
            right: -150px;
            position: relative;
        }
    }
}
.listing-blog-post {
    .title {
        margin-bottom: 0 !important;
    }
}
.listing-widget-post {
    list-style: none;
    margin: 0 30px;
    padding: 0;
    li {
        display: inline-block;
        margin-bottom: 5px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            bottom: -22px;
            background-color: #ededed;
        }
        &:last-child::before {
            display: none;
        }
        .img {
            display: inline-block;
            margin-right: 15px;
        }
        .content {
            display: inline-block;
            position: relative;
            top: 36px;
            span {
                display: block;
                font-size: 14px;
                color: var(--mainColor);
            }
            h4 {
                display: block;
                font-size: 18px;
                color: var(--titleColor);
                position: relative;
                font-weight: 500;
            }
            p {
                font-size: 14px;
                max-width: 200px;
                color: var(--paragraphColor);
                i {
                    font-size: 14px;
                    color: var(--mainColor);
                }
            }
        }
    }
}
/*=================================
Listing Details Area End
====================================*/

/*=================================
Application Area
====================================*/
.application-area {
    background-color: #fef6f2;
    position: relative;
    z-index: 1;
    margin-bottom: 150px;
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        background-image: url(../img/shape/shape1.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        animation: flash  3s linear infinite;
    }
}
.application-area-two {
    background-color: #fef6f2;
    overflow: hidden;
}
.application-img {
    position: relative;
    margin-bottom: -150px;
}
.application-content {
    margin-left: 40px;
    .section-title {
        h2 {
            max-width: 550px;
        }
        p {
            max-width: 580px;
            color: #737373;
        }
    }
}
.application-btn {
    margin-top: 30px;
    .application-play-btn {
        display: inline-block;
        width: 185px;
        height: 60px;
        list-style: 60px;
        background-color: var(--titleColor);
        padding: 0 15px;
        border-radius: 7px;
        margin-right: 20px;
        overflow: hidden;
        transition: var(--transition);
        &:hover {
            background-color: var(--mainColor);
        }
        img {
            width: 40px;
            position: relative;
            top: -5px;
        }
        .btn-content {
            display: inline-block;
            padding-top: 3px;
            span {
                font-size: 10px;
                color: var(--whiteColor);
            }
            h3 {
                font-size: 18px;
                color: var(--whiteColor);
                margin-bottom: 0;
                font-weight: 400;
                line-height: 1;
            }
        }
    }
}
.application-img-two {
    position: relative;
    z-index: 1;
    img {
        margin-left: 75px;
    }
    &::before {
        content: '';
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        width: 565px;
        height: 565px;
        border-radius: 50%;
        margin: 0 auto;
        background-color: var(--mainColor);
        z-index: -1;
    }
}
.application-area-two {
    .application-content {
        margin-left: 0 !important;
    }
}
/*=================================
Application Area End
====================================*/

/*=================================
Blog Area End
====================================*/
.blog-card {
    margin-bottom: 30px;
    a {
        display: block;
        img {
            max-width: 100%;
        }
    }
    .content {
        padding: 30px 15px;
        border:  1px dashed var(--optionalColor);
        span {
            font-size: 14px;
            font-weight: 500;
            a {
                display: inline-block;
                color: var(--mainColor);
                &:hover {
                    color:var(--titleColor)
                }
            }
        }
        h3 {
            margin-top: 10px;
            a {
                color: var(--titleColor);
            }
        }
        .read-more {
            font-size: 15px;
            color: var(--mainColor);
            font-weight: 500;
            text-decoration: underline;
            &:hover {
                color: var(--titleColor);
            }
        }
    }
}
.blog-article {
    margin-bottom: 30px;
   
    .article-comment-area {
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
        .article-img {
            margin-bottom: 30px;
        }
        .article-comment {
            margin-top: 40px;
            padding-left: 30px;
            padding-right: 30px;
            list-style-type: none;
            padding-top: 0;
            margin-bottom: 0;
            padding-bottom: 15px;
            border-top: 1px solid #ededed;
            border-bottom: 1px solid #ededed;
            li {
                display: inline-block;
                text-align: left;
                position: relative;
                margin-right: 150px;
                padding-top: 10px;
                &:last-child {
                    margin-right: 0;
                    float: right;
                }
                .image {
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 10px;
                }
                .content {
                    display:block;
                    margin-left: 55px;
                    padding-top: 0;
                    h3 {
                        font-size: 22px;
                        color: var(--titleColor);
                        display: block;
                        line-height: 1;
                        font-weight: 500;
                    }
                    span {
                        font-size: 15px;
                        color: var(--mainColor);
                        display: block;
                    }
                }
                .content-list {
                    display: block;
                    margin-left: 15px;
                    padding-top: 10px;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 20px;
                        left: -15px;
                        width: 1px;
                        height: 45px;
                        background-color: #ededed;
                    }
                    h3 {
                        font-size: 20px;
                        color: var(--titleColor);
                        display: block;
                        font-weight: 500;
                    }
                    span {
                        font-size: 15px;
                        color: var(--mainColor);
                        display: block;
                    }
                }
            }
        }
    } 
    .article-content {
        padding: 35px 30px;
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
        h3 {
            font-size: 25px;
            margin-bottom: 20px;
            font-weight: 500;
        }
        .content-text {
            position: relative;
            &::before {
                content: 'D';
                position: absolute;
                font-size: 100px;
                color: var(--titleColor);
                top: -35px;
                left: 0;
                font-weight: 500;
            }
            p {
                margin-left: 75px;
                margin-bottom: 10px;
            }
        }
        .blockquote {
            position: relative;
            margin-bottom: 20px;
            margin-top: 30px;
            background-color: #fff6f2;
            padding: 20px;
            border: 1px dashed var(--mainColor);
            &::before {
                content: '\f114';
                position: absolute;
                top: -20px;
                right: 20px;
                font-size: 30px;
                font-weight: 400;
                color: var(--mainColor);
                font-family: Flaticon;
            }
            p {
                font-size: 19px;
                color: var(--titleColor);
                font-weight: 400;
                margin-top: 20px;
                margin-right: 15px;
            }
        }
    }
    .blog-tag {
        margin-top: 30px;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        padding: 12px 0;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                padding: 5px 8px;
                transition: 0.7s;
                color: var(--titleColor);
                a {
                    color: var(--mainColor);  
                }
                &:hover a{
                    color: var(--titleColor);
                }
            }
        }
    }
    .article-post {
        position: relative;
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
        margin-bottom: 40px;
        margin-top: 40px;
        padding: 30px;
            &::before {
                content: '';
                position: absolute;
                top: 40px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 1px;
                height: 100px;
                background-color: #eeeeee;
            }
        .article-post-share {
            padding: 15px 30px;
            span {
                font-size: 14px;
                color: var(--mainColor);
                position: relative;
                margin-left: 40px;
                display: inline-block;
                &::before {
                    content: '';
                    position: absolute;
                    left: -35px;
                    width: 30px;
                    height: 1px;
                    bottom: 0;
                    background-color: var(--mainColor);
                }
                a {
                    color: var(--paragraphColor);
                    display: inline-block;
                    margin-bottom: 0;
                    color: var(--mainColor);
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
            a {
                display: block;
                h3 {
                    font-size: 18px;
                    margin-bottom: 0;
                    margin-top: 15px;
                    transition: 0.7s;
                    max-width: 600px;
                    color: #6a8096;
                    font-weight: 400;
                    &:hover {
                        color: var(--mainColor);
                        text-decoration: underline;
                    }
                    &.active {
                        color: var(--titleColor);
                        text-decoration: underline;
                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                }
            }
            &.text-align {
                float: right;
            }
            &.active {
                margin-left: 20px;
                background-color: #fff6f2;
                border: 1px dashed var(--mainColor);
            }
        }
    }
    .comments-wrap {
        padding-bottom: 20px;
        margin-top: 20px;
        padding-top: 40px;
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
        .title {
            font-size: 20px;
            margin-bottom: 30px;
            font-weight: 400;
            margin-left: 30px;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                position: relative;
                padding: 30px;
                padding-left: 150px;
                margin-bottom: 30px;
                border-bottom: 1px solid #eeeeee;
                &:last-child {
                    margin-bottom: 0;
                    border: none;
                }
                img {
                    border-radius: 50%;
                    position: absolute;
                    top: 35px;
                    left: 30px;
                }
                h3 {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 500;
                }
                span {
                    margin-bottom: 10px;
                    display: block;
                }
                p {
                    margin-bottom: 0;
                }
                a {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    color: var(--mainColor);
                    font-weight: 500;
                    &:hover {
                        color: var(--titleColor);
                    }
                }
                &.ml-30 {
                    margin-left: 30px;
                }
            }
        }
    }
    .comments-form {
        margin-top: 40px;
        background-attachment: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
        .contact-form {
            .agree-label {
                label {
                    font-weight: 400;
                    color: #636363;
                    margin-left: 5px;
                    font-size: 15px;
                }
                margin-bottom: 20px;
            }
        }
    }
}

.blog-widget {
    margin-bottom: 35px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
    .title {
        font-size: 20px;
        color: var(--titleColor);
        border-bottom: 1px solid #ededed;
        padding: 15px 30px;
        font-weight: 400;
    }
    .search-widget {
        padding: 20px 30px 30px;
        .search-form {
            position: relative;
            .form-control {
                height: 50px;
                border: 1px solid #ededed;
                background-color: #f4f4f4;
                box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
                padding: 10px 20px;
                width: 100%;
                border-radius: 0;
                &:focus {
                    border-color: var(--optionalColor);
                }
            }
            button {
                position: absolute;
                top: 0;
                right: 0;
                height: 50px;
                width: 50px;
                background-color: var(--mainColor);
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
                border: none;
                outline: none;
                i {
                    color: var(--whiteColor);
                  }
                  
                &:hover {
                    background-color: var(--titleColor);
                }
            }
        }
    }
    .widget-popular-post {
        position: relative;
        overflow: hidden;
        padding: 20px  30px 30px;
        .item {
            overflow: hidden;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #efefef;
            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
            .thumb {
                float: left;
                overflow: hidden;
                position: relative;
                margin-right: 15px;
                .full-image {
                    width: 75px;
                    height: 105px;
                    display: inline-block;
                    background-size: cover !important;
                    background-repeat: no-repeat;
                    background-position: center center !important;
                    position: relative;
                    background-color: var(--titleColor);
                    &.bg1 {
                        background-image: url(../img/blog/blog-post1.png);
                    }
                    &.bg2 {
                        background-image: url(../img/blog/blog-post2.png);
                    }
                    &.bg3 {
                        background-image: url(../img/blog/blog-post3.png);
                    }
                    
                }
            }
            .info {
                overflow: hidden;
                span {
                    display: block;
                    color: var(--mainColor);
                    text-transform: uppercase;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: 500;
                }
                .title-text {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font-size: 15px;
                    font-weight: 600;
                    a {
                        display: inline-block;
                        color: var(--titleColor);
                        &:hover {
                            color: var(--titleColor);
                        }
                    }
                }
                p {
                    font-size: 14px;
                    margin-bottom: 0;
                    max-width: 180px;
                }
            }
        }
    }
    .blog-widget-tag {
        list-style: none;
        margin: 0;
        padding: 10px 20px 30px;
        li {
            display: inline-block;
            padding: 0 5px;
            transition: 0.7s;
            color: #777777;
            font-size: 16px;
            a {
                color: #777777;
            }
            &:hover a {
                color: var(--mainColor);
            }
        }
    }
    .widget_categories {
        padding: 20px 30px;
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            li {
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 600;
                border-bottom: 1px solid #ededed;
                a {
                    display: block;
                    color: var(--paragraphColor);
                    font-weight: normal;
                    span {
                        float: right;
                        font-size: 13px;
                        color: var(--mainColor);
                        font-weight: 600;
                    }
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }              
        }
    }
    .widget-post-into {
        padding: 20px 30px 30px;
        .blog-widget-post {
            span {
                color: var(--mainColor);
                font-size: 14px;
                font-weight: 600;
            }
            a {
                display: block;
                h3 {
                    font-size: 16px;
                    color: #777777;
                    position: relative;
                    margin-left: 40px;
                    margin-top: 10px;
                    max-width: 280px;
                    font-weight: 500;
                    &::before {
                        content: '';
                        position: absolute;
                        left: -35px;
                        width: 30px;
                        height: 1px;
                        top: 15px;
                        background-color: #777777;
                    }
                }
            }
        }
    }
}
.blog-widget-profile {
    padding: 30px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
    text-align: center;
    margin-bottom: 35px;
    .images {
        background-color: #fff6f2;
        padding: 15px 30px;
        border: 1px dashed var(--mainColor);
    }
    .content {
        border-bottom: 1px solid #ededed;
        padding-bottom: 15px;
        h3 {
            font-size: 16px;
            color: var(--titleColor);
            font-weight: 500;
            margin-top: 30px;
            margin-bottom: 7px;
        }
        span {
            font-size: 14px;
            color: var(--mainColor);
        }
    }
   
    p {
        padding-top: 20px;
        font-size: 15px;
        text-align: center;
        margin-bottom: 15px;
    }
    .social-link {
        margin: 20px 0 0 0;
        padding: 0;
        list-style: 0;
        li {
            display: inline-block;
            margin-right: 5px;
            a {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                line-height: 37px;
                text-align: center;
                color: var(--whiteColor);
            }
            &:nth-child(1) a {
                background-color: #3b5998;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(2) a {
                background-color: #55acee;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(3) a {
                background-color: #3f729b;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(4) a {
                background-color: #ff0000;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(5) a {
                background-color: #c80000;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
        }
    }
}
/*=================================
Blog Area End
====================================*/

/*=================================
Testimonial Area 
====================================*/
.testimonial-area {
    position: relative;
    overflow: hidden;
    .owl-dots {
        margin-top: 0px !important;
        margin-bottom: 30px;
        .owl-dot {
            span {
                background-color: var(--mainColor) !important;
                width: 5px !important;
                height: 5px !important;
                transition: 0.7s;
            }
            &.active span {
                background-color: var(--mainColor) !important;
                width: 10px !important;
                height: 10px !important;
            }
            &:hover span {
                background-color: var(--mainColor) !important;
                width: 10px !important;
                height: 10px !important;
            }
        }
    }
    .testimonial-slider {
        .center {
            .testimonial-item {
                background-color: var(--whiteColor);
                box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
            }
        }
    }
}
.testimonial-area-two {
    background-image: url(../img/testimonial/testimonial-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .owl-nav {
        margin-top: 0;
        margin-bottom: 15px;
        .owl-prev {
            color: #bbbbbb !important;
            font-size: 24px !important;
            font-weight: 700;
            &:hover {
                color: var(--mainColor) !important;
                background-color: transparent !important;
            }
        }
        .owl-next {
            color: #bbbbbb !important;
            font-size: 24px !important;
            font-weight: 700;
            &:hover {
                color: var(--mainColor) !important;
                background-color: transparent !important;
            }
        }
    }
}

.testimonial-area-three {
    background-image: url(../img/testimonial/testimonial-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .testimonial-item {
        margin-top: 0 !important;
    }
}
.testimonial-item-bg {
    background-color: #f7f7f7;
}
.testimonial-item-bg2 {
    background-color: var(--whiteColor);
}
.testimonial-item {
    position: relative;
    padding: 30px;
    margin-top: 45px;
    margin-bottom: 30px;
    h3 {
        font: 18px;
        margin-bottom: 5px;
    }
    span {
        font-size: 14px;
        color: var(--mainColor);
    }
    p {
        margin-bottom: 15px;
        margin-top: 10px;
        font-size: 15px;
    }
    .rating {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            color: var(--mainColor);
        }
    }
    .testimonial-top {
        position: absolute;
        top: 0;
        right: 0;
        i {
            font-size: 60px;
            color: var(--optionalColor);
            padding-right: 30px;
            padding-top: 30px;
        }
    }
}
/*=================================
Testimonial Area End
====================================*/

/*=================================
Client Area 
====================================*/
.client-area {
    position: relative;
    .owl-nav {
        position: absolute;
        bottom: 40px;
        margin-top: 0;
        margin-bottom: 15px;
        left: 110px;
        right: 0;
        .owl-prev {
            color: #bbbbbb !important;
            font-size: 24px !important;
            font-weight: 700;
            &:hover {
                color: var(--mainColor) !important;
                background-color: transparent !important;
            }
        }
        .owl-next {
            color: #bbbbbb !important;
            font-size: 24px !important;
            font-weight: 700;
            &:hover {
                color: var(--mainColor) !important;
                background-color: transparent !important;
            }
        }
    }
}
.client-bg {
    position: relative;
    width: 100%;
    margin-bottom: 110px;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 360px;
        width: 100%;
        left: 0;
        right: 0;
        background-image: url(../img/testimonial/testimonial-bg3.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
.client-img {
    position: relative;
    img {
        width: 520px !important;
    }
}
.client-content {
    margin-top: 50px;
    h3 {
        font-weight: 500;
        margin-bottom: 10px;
    }
    span {
        color: var(--mainColor);
        font-weight: 400;
    }
    p {
        margin-top: 20px;
        color: #555555;
        font-size: 18px;
        margin-bottom: 0;
        max-width: 480px;
    }
}
/*=================================
Client Area End
====================================*/

/*=================================
Product Area 
====================================*/
.product-card {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    border: 1px dashed #eeeeee;
    margin-bottom: 30px;
    position: relative;
    .product-content {
        padding: 30px 20px;
        ul {
            list-style: none;
            padding: 0;
            line-height: 1;
            margin-bottom: 15px;
            li {
                display: inline-block;
                color: var(--mainColor);
                padding: 0 5px;
                del {
                    color: var(--titleColor);
                }
            }
        }
        .rating {
            list-style: none;
            margin: 0 0 15px 0;
            padding: 0;
            float: right;
            li {
                color:var(--mainColor);
                font-size: 14px;
                display: inline-block;
            }
        }
        a {
            display: block;
            h3 {
                text-transform: capitalize;
                margin-bottom: 0;
                transition: all 0.5s;
                font-size: 20px;
                &:hover {
                    color: var(--mainColor);
                }
            }
        }
       
        .default-btn {
            &.product-btn {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 180px;
                bottom: 200px;
                padding: 10px 20px;
                transform: scale(0);
                i {
                    font-size: 20px;
                    position: relative;
                    top: 3px;
                    margin-right: 5px;
                }
            }
        }
    }
    &:hover {
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        .default-btn {
            &.product-btn {
                transform: scale(1);
            }
        }
    }
}
/*=================================
Product Area End
====================================*/
/*=================================
Cart Wraps Area
===================================*/
.cart-wraps-area {
    .cart-table {
        table {
            margin-bottom: 0;
            thead {
                tr {
                    th {
                        border-bottom-width: 0px;
                        vertical-align: middle;
                        padding: 15px 0;
                        text-transform: uppercase;
                        border: none;
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: var(--titleColor);
                        padding-left: 0;
                        padding-right: 0;
                        font-size: 15px;
                        border-color: #eeeeee;
                        border-left: none;
                        border-right: none;
                        &.product-thumbnail {
                            a {
                                display: block;
                                img {
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                        }
                        &.product-name {
                            a {
                                color: var(--titleColor);
                                font-weight: 600;
                                display: inline-block;
                                font-size: 16px;
                                &:hover {
                                    color: var(--mainColor) !important;
                                }
                            }
                        }
                        &.product-subtotal {
                            .remove {
                                color: var(--titleColor);
                                float: right;
                                position: relative;
                                top: 1px;
                                -webkit-transition: all 0.5s;
                                transition: all 0.5s;
                                i {
                                    font-size: 24px;
                                }
                                &:hover {
                                    color: var(--mainColor);
                                }
                            }
                            span {
                                font-weight: 600;
                            }
                        }
                        &.product-quantity {
                            .input-counter {
                                max-width: 130px;
                                min-width: 130px;
                                text-align: center;
                                display: inline-block;
                                position: relative;
                                span {
                                    position: absolute;
                                    top: 0;
                                    background-color: transparent;
                                    cursor: pointer;
                                    color: var(--titleColor);
                                    width: 40px;
                                    height: 100%;
                                    line-height: 48px;
                                    transition: 0.5s;
                                    &:hover {
                                        color: var(--paragraphColor) !important;
                                    }
                                }
                                .minus-btn {
                                    left: 0;
                                    &:hover {
                                        color: var(--paragraphColor) !important;
                                    }
                                }
                                .plus-btn {
                                    right: 0;
                                    &:hover {
                                        color: var(--paragraphColor) !important;
                                    }
                                }
                                input {
                                    height: 45px;
                                    color: var(--titleColor);
                                    outline: 0;
                                    display: block;
                                    border: none;
                                    background-color: #f8f8f8;
                                    text-align: center;
                                    width: 100%;
                                    font-size: 17px;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cart-buttons {
        margin-top: 30px;
    }
    .cart-totals {
        background: #ffffff;
        padding: 46px;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        border-radius: 0;
        margin: auto;
        margin-top: 50px;
        h3 {
            font-size: 24px;
            margin-bottom: 25px;
        }
        ul {
            padding: 0;
            margin: 0 0 25px;
            list-style-type: none;
            li {
                border: 1px solid var(--optionalColor);
                padding: 10px 15px;
                color: var(--titleColor);
                overflow: hidden;
                font-weight: 500;
                &:first-child {
                    border-bottom: none;
                }
                &:nth-child(3) {
                    border-top: none;
                }
                &:last-child {
                    border-top: none;
                }
                span {
                    float: right;
                    color: var(--paragraphColor);
                    font-weight: normal;
                }
            }
        }
    }
      
    .cart-calc {
        margin-top: 50px;
        background-color: #ffffff;
        padding: 40px;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        .cart-wraps-form {
            h3 {
                font-size: 24px;
                color: var(--titleColor);
                padding-bottom: 20px;
            }
            .form-group {
                margin-bottom: 20px;
                select {
                    height: 50px;
                    padding: 7px 18px;
                    color: #6c777d;
                    border: 1px solid var(--optionalColor);
                    &:focus,:hover {
                        outline: 0;
                        box-shadow: none;
                        border: 1px solid var(--titleColor);
                        background-color: var(--mainColor) !important;
                    }
                    option {
                        padding: 10px;
                        &:hover {
                            background-color: var(--mainColor) !important;
                        }
                    }
                }
                .form-control {
                    font-size: 16px;
                    border: 1px solid var(--optionalColor);
                    color: #6c777d;
                    padding: 12px 18px;
                    font-weight: 400;
                    &:focus,:hover {
                        outline: 0;
                        box-shadow: none;
                        border: 1px solid var(--titleColor);
                    }
                }
            }
        }
        .nice-select {
            height: 50px;
            width: 100%;
            margin-bottom: 19px;
            border: 1px solid var(--optionalColor);
            .list {
                width: 100%;
            }
            .option {
                color: var(--titleColor) !important;
                :hover {
                    color: #ffffff !important;
                    background-color: var(--mainColor) !important;
                }
            }
            .current {
                position: relative;
                top: 4px;
            }
        }
    }
}
.cart-wraps-area .nice-select .option.focus, .cart-wraps-area .nice-select .option.selected.focus, .cart-wraps-area .nice-select .option:hover {
   background-color: var(--mainColor) !important;
   color: var(--whiteColor) !important;
   font-weight: 600;
}
.cart-wraps-area .cart-calc select .option.focus, .cart-wraps-area .cart-calc .nice-select .option.selected.focus {
    background-color: var(--mainColor) !important;
    color: #ffffff !important;
}

.cart-wraps-area .cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
    color: var(--titleColor);
}

.cart-wraps-area .cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
    color: var(--titleColor);
}

.cart-wraps-area.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
    color: var(--titleColor);
}

.cart-wraps-area .cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
    color: var(--titleColor);
}

.cart-wraps-area .cart-calc .cart-wraps-area form .form-control input::-webkit-input-placeholder {
    color: #6c777d;
}

.cart-area .cart-calc .cart-wraps-area form .form-control input:-ms-input-placeholder {
    color: #6c777d;
}

.cart-area .cart-area .cart-calc .cart-wraps-area .form-control input::-ms-input-placeholder {
    color: #6c777d;
}

.cart-area .cart-area .cart-calc .cart-wraps-area .form-control input::placeholder {
    color:#495057;
}
/*=================================
Cart Wraps Area End
===================================*/

/*=================================
Checkout Area 
===================================*/
.checkout-area {
    .checkout-user {
        box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.08);
        background: var(--whiteColor);
        padding: 20px 15px;
        margin-bottom: 65px;
        border-top: 3px solid var(--mainColor);
        color: var(--mainColor);
        span {
            color: var(--titleColor);
            font-size: 20px;
            a {
                color: var(--mainColor);
                &:hover {
                    color: var(--titleColor);
                }
            }
        }
    }
}
.billing-details {
    margin-bottom: 30px;
    background-color: var(--whiteColor);
    padding: 50px 30px 25px 30px;
    box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
    h3 {
        font-size: 24px;
        color: var(--titleColor);
        margin-bottom: 15px;
        font-weight: 600;
    }
    .form-group {
        margin-bottom: 25px;
        label {
            color: var(--titleColor);
            display: block;
            margin-bottom: 10px;
            font-weight: 500;
            span {
                color: var(--mainColor);
            }
        } 
        .form-control {
            height: 50px;
            color: #2d3652;
            border: 1px solid #e8e8e8;
            background-color: #fcfcff;
            border-radius: 0;
            padding: 10px 20px;
            width: 100%;
            &:focus,:hover {
                outline: 0;
                box-shadow: none;
                border: 1px solid var(--paragraphColor);
            }
        }
        .form-message {
            font-size: 16px;
            border: 1px solid #e8e8e8;
            background-color: #fcfcff;
            padding: 18px 18px;
            font-weight: 400;
            width: 100%;
            &:focus,:hover {
                outline: 0;
                box-shadow: none;
                border: 1px solid var(--paragraphColor);
            }
        }
        .nice-select {
            float: unset;
            line-height: 45px;
            color: var(--titleColor);
            padding-top: 0;
            padding-bottom: 0;
            .list {
                background-color: #ffffff;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
                .option {
                    transition: 0.5s;
                    padding-left: 20px;
                    padding-right: 20px;
                    &:hover {
                        background-color: var(--mainColor) !important;
                        color: #ffffff;
                    }
                    &:focus {
                        border: none;
                        outline: none;
                    }
                    .selected {
                        background-color: transparent;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
    }
    .form-check {
        margin-bottom: 15px;
        .form-check-input {
            width: 15px;
            height: 15px;
        }
        .form-check-label {
            color:var(--titleColor);
            margin-left: 5px;
            font-weight: 500;
        } 
    }
}

.checkout-area .billing-details .form-group .nice-select .option:hover, .checkout-area .billing-details .form-group .nice-select .option.focus, .checkout-area .billing-details .form-group .nice-select .option.selected.focus {
    background-color: var(--mainColor) !important;
    color: #ffffff !important;
    border: none;
    outline: none;
}

.order-details {
    .order-table {
        background-color: var(--whiteColor);
        padding: 50px 30px;
        box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
        h3 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        table {
            margin-bottom: 0;
            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        padding-left: 20px;
                        padding-top: 15px;
                        padding-right: 20px;
                        padding-bottom: 15px;
                        font-weight: 600;
                        color: var(--titleColor);
                        font-size: 18px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: var(--titleColor);
                        border-color: #eaedff;
                        font-size: 14px;
                        padding-left: 20px;
                        padding-right: 20px;
                        font-weight: 600;
                        &.product-name {
                            a {
                                color: #6e768f;
                                display: inline-block;
                                font-weight: 600;
                                &:hover {
                                    color: var(--titleColor);
                                }
                            }
                        }
                        &.order-subtotal {
                            span {
                                color: var(--titleColor);
                                font-weight: 600;
                            }
                        }
                        &.order-shipping {
                            span {
                                color: var(--titleColor);
                                font-weight: 700;
                            }
                        }
                        &.total-price {
                            span {
                                color: var(--titleColor);
                                font-weight: 700;
                            }
                        } 
                        &.shipping-price {                         
                            font-weight: 700;
                        }
                        &.order-subtotal-price {
                            font-weight: 700;
                        }
                        &.product-subtotal {
                            font-weight: 700;
                        }                          
                    } 
                }
            }
        }          
    }
    .payment-box {
        background-color: var(--whiteColor);
        box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
        margin-top: 30px;
        margin-bottom: 30px;
        padding:50px 30px;
        .payment-method {
            p [type="radio"]:checked {
                display: none;
            }
            p [type="radio"]:checked + label {
                padding-left: 27px;
                cursor: pointer;
                display: block;
                font-weight: 600;
                color: var(--titleColor);
                position: relative;
                margin-bottom: 8px;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #dddddd;
                    border-radius: 50%;
                    background: #ffffff;
                }
                &::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: var(--mainColor);
                    position: absolute;
                    top: 6px;
                    left: 3px;
                    border-radius: 50%;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }                
            }
            p [type="radio"]:not(:checked) {
                display: none;
            }
            p [type="radio"]:not(:checked) + label {
                padding-left: 27px;
                cursor: pointer;
                display: block;
                font-weight: 600;
                color: #172541;
                position: relative;
                margin-bottom: 8px;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #dddddd;
                    border-radius: 50%;
                    background: #ffffff;
                }
                &::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: var(--mainColor);
                    position: absolute;
                    top: 6px;
                    left: 3px;
                    border-radius: 50%;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    opacity: 0;
                    visibility: hidden;
                    transform: scale(0);
                }     
            }
        }
        .order-btn {
            margin-top: 20px;
            display: block;
            text-align: center;
            width: 100%;
            padding: 12px 27px;
            color: var(--whiteColor);
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 1;
            background-color: var(--titleColor);
            &::before {
                content: "";
                position: absolute;
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: var(--mainColor);
                z-index: -1;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
            }      
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 100%;
                background-color: var(--mainColor);
                z-index: -1;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
            }
            &:hover {
                color: var(--whiteColor);
                border: none;
            }
            &:hover::before {
                left: auto;
                right: 0;
                width: 50%;
            }
            &:hover::after {
                left: 0;
                right: 0;
                width: 50%;
            }
        }
    }
}
.billing-details .form-group .nice-select .option:hover,  .billing-details .form-group .nice-select .option.focus, .billing-details .form-group .nice-select .option.selected.focus {
    background-color: var(--mainColor) !important;
    color: #ffffff !important;
}
/*=================================
Checkout Area End
===================================*/

/*==================================
Shop Details 
====================================*/
.shop-detls-image {
    margin-bottom: 30px;
    border: 1px dashed #eeeeee;
    &:hover {
        border-color: var(--mainColor);
    }
}
.shop-desc {
    h3 {
        margin-bottom: 12px;
        font-size: 24px;
        font-weight: 600;
    }
    .price {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 600;
        .old-price {
            text-decoration: line-through;
            color: var(--titleColor);
            margin-left: 10px;
        }
    }
    .shop-review {
        margin-bottom: 15px;
        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 14px;
            i {
                color: var(--mainColor);
            }
        }
        .rating-count {
            margin-left: 5px;
            display: inline-block;
            color:  var(--titleColor);
            border-bottom: 1px solid var(--titleColor);
            line-height: initial;
            &:hover {
                color: var(--mainColor);
                border-color: var(--mainColor);
            }              
        }
    }
    p {
        margin-bottom: 0;
    }

    .input-count-area {
        h3 {
            font-size: 16px;
            display: inline-block;
            font-weight: 500;
            margin-right: 15px;
        }
        .input-counter {
            margin-top: 10px;
            max-width: 130px;
            min-width: 130px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;
            margin-bottom: 15px;
            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: var(--mainColor);
                width: 50px;
                height: 100%;
                line-height: 45px;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                &:hover {
                    color: var(--mainColor);
                }
            }
            .minus-btn {
                left: 0;
            }
            .plus-btn {
                right: 0;
            }
            input {
                height: 45px;
                color: var(--titleColor);
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
    .shop-add {
        margin-top: 20px;
        .default-btn {
            border: none;
            margin-right: 20px;
        }
    }
    .shop-share {
        margin-top: 30px;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                margin: 0 3px;
                span {
                    color: var(--paragraphColor);
                    margin-right: 5px;
                    font-size: 16px;
                }
                a {
                    width: 35px;
                    height: 35px;
                    background-color: var(--mainColor);
                    font-size: 16px;
                    color: var(--whiteColor);
                    line-height: 35px;
                    border-radius: 50px;
                    text-align: center;
                    &:hover {
                        background-color: var(--titleColor);
                    }
                }
            }
        }
    }

}
.shop .input-count-area .input-counter input::-webkit-input-placeholder {
    color: var(--titleColor);
}
  
.shop .input-count-area .input-counter input:-ms-input-placeholder {
    color: var(--titleColor);
}
  
.shop .input-count-area .input-counter input::-ms-input-placeholder {
    color: var(--titleColor);
}
  
.shop .input-count-area .input-counter input::placeholder {
    color: var(--titleColor);
}
  
.tab {
    .tabs_item {
        display: none;
        &:first-child {
            display: block;
        }
    }
}

.shop-detls-tab {
    margin-top: 30px;
    .tabs {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-block;
            line-height: initial;
            margin-right: 5px;
            a {
                display: inline-block;
                position: relative;
                text-transform: uppercase;
                color: var(--titleColor);
                border: 1px dashed #eeeeee;
                padding: 17px 30px 13px 30px;
                &:focus {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                }
            }
            &.active a {
                color: var(--whiteColor);
                background-color: var(--mainColor);
                border-color: var(--mainColor);
            }
            &.current a {
                color: var(--whiteColor);
                background-color: var(--mainColor);
                border-color: var(--mainColor);
            }
        }
    }
    .tab_content {
        border: 1px dashed #eeeeee;
        padding: 30px;
        .tabs_item {
            .shop-detls-tab-content {
                p {
                    color: var(--titleColor);
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }                      
                }
                .additional-information {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;
                    text-align: left;
                    li {
                        border: 1px solid #eeeeee;
                        border-bottom: none;
                        padding: 10px 15px 7px;
                        color: #737b9a;
                        &:last-child {
                            border-bottom: 1px solid #eeeeee;
                        }
                        span {
                            display: inline-block;
                            width: 40%;
                            color: #212529;
                            font-weight: 600;
                        }
                    }
                }
                h3 {
                    margin-bottom: 15px;
                    font-size: 24px;
                    color: var(--titleColor);
                }
                .shop-review-form {
                    .review-title {
                        position: relative;
                        .rating {
                            display: inline-block;
                            
                            i {
                                color: var(--mainColor);
                                font-size: 14px;
                            }
                            p {
                                margin-bottom: 0;
                                display: inline-block;
                                padding-left: 5px;
                                line-height: initial;
                            }                         
                        }
                        .btn-right {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            font-size: 14px;
                            color: var(--whiteColor);
                            background-color: var(--titleColor);
                            padding: 7px 15px;
                            border-radius: 5px;
                            &:hover {
                                background-color: var(--mainColor);
                                color: var(--whiteColor);
                            }
                        }   
                    }
                    .review-comments {
                        margin-top: 35px;
                        .review-item {
                            margin-top: 30px;
                            position: relative;
                            padding-right: 200px;
                            border-top: 1px dashed #eeeeee;
                            padding-top: 30px;
                            .rating  {
                                i {
                                    color: var(--mainColor);
                                    font-size: 14px;
                                }
                            }
                            h3 {
                                font-size: 24px;
                                margin-top: 10px;
                                margin-bottom: 10px;
                                color: var(--titleColor);
                            }
                            span {
                                margin-bottom: 10px;
                                font-size: 16px;
                                display: block;
                                font-weight: 500;
                                strong {
                                    font-weight: 600;
                                } 
                            }
                            p {
                                margin-bottom: 0;
                            }
                            .review-report-link {
                                position: absolute;
                                right: 0;
                                color: #737b9a;
                                top: 40px;
                                text-decoration: underline;
                                font-weight: 600;
                                font-size: 15px;
                                &:hover {
                                    color: var(--mainColor);
                                }                                  
                            }      
                        }                          
                    }
                    .review-form {
                        margin-top: 30px;
                        h3 {
                            margin-bottom: 20px;
                        }
                        .contact-wrap-form {
                            box-shadow: none;
                            padding: 0;
                            .contact-form {
                                padding: 50px;
                                max-width: 100% ;
                                margin: 0 auto 0;
                                background-color: #f4f4f4;
                            }
                        }
                    }  
                } 
            }
        }
    }
}
/*==================================
Shop Details End
====================================*/ 

/*=================================
Footer Area 
====================================*/
.footer-bg {
    background-color: #2a2a2a;
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        background-image: url(../img/shape/footer-dots.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
.footer-bg2 {
    background-color: #2a2a2a;
}
.footer-top {
    padding-top: 50px;
    padding-bottom: 40px;
    border-bottom: 1px dashed #393939;
}
.newsletter-title {
    i {
        font-size: 50px;
        color: var(--whiteColor);
        display: inline-block;
    }
    h2 {
        font-size: 30px;
        color: var(--whiteColor);
        font-weight: 500;
        margin-left: 20px;
        display: inline-block;
        margin-bottom: 0;
    }
}
.newsletter-form {
    position: relative;
    max-width: 550px;
    border-radius: 50px;
    .form-control {
        background: var(--whiteColor);
        height: 55px;
        line-height: 55px;
        margin: 0;
        border-radius: 50px;
        border: none;
        padding: 0 25px;
        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }
    }
    .default-btn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        line-height: 55px;
        padding: 0 30px;
        height: 55px;
        border: 0;             
    }
    .validation-danger {
        font-size: 18px;
        margin-top: 15px;
        color: red;
    }
}

.footer-widget {
    margin-bottom: 30px;
    .logo {
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 15px;
        color: #a6a6a6;
        max-width: 290px;
        font-weight: 500;
        &:last-child {
            margin-bottom: 0;
        }
    }
    h3 {
        margin-top: 30px;
        color: var(--whiteColor);
        margin-bottom: 15px;
        font-weight: 500;
    }
    .footer-contact-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: block;
            font-size: 15px;
            margin-top: 5px;
            color: #a6a6a6;
            span {
                color: #a6a6a6;
                font-weight: 500;
            }
            a {
                color: #a6a6a6;
            }
        }
    }
    .social-link {
        margin: 20px 0 0 0;
        padding: 0;
        list-style: 0;
        li {
            display: inline-block;
            margin-right: 5px;
            a {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                line-height: 37px;
                text-align: center;
                color: var(--whiteColor);
            }
            &:nth-child(1) a {
                background-color: #3b5998;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(2) a {
                background-color: #55acee;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(3) a {
                background-color: #3f729b;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(4) a {
                background-color: #ff0000;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
            &:nth-child(5) a {
                background-color: #c80000;
                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
        }
    }
    .footer-news {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            margin-bottom: 5px;
            .img {
                display: inline-block;
                margin-right: 15px;
                a {
                    display: inline-block;
                }
            }
            .content {
                display: inline-block;
                position: relative;
                top: 10px;
                h4 {
                    display: block;
                    font-size: 14px;
                    color: #d7d7d7;
                    position: relative;
                    margin-left: 30px;
                    font-weight: 500;
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: -30px;
                        width: 30px;
                        height: 1px;
                        background-color: #d7d7d7;
                    }
                }
                span {
                    display: block;
                    font-size: 15px;
                    color: #d7d7d7;
                    a {
                        display: block;
                        color: #d7d7d7;
                    }
                    &:hover a {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
    .footer-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: block;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: #bdcbd5;
            }
            &:hover a{
                color: var(--mainColor);
                letter-spacing: 0.25px;
            }
        }
    }
    .footer-form {
        .form-group {
            margin-bottom: 15px;
            .form-control {
                border: 0;
                border-radius: 0;
                height: 45px;
                line-height: 45px;
                padding: 0 20px;
                &:focus {
                    outline: none;
                    box-shadow: none;
                    border: none;
                }
                &:hover {
                    outline: none;
                    box-shadow: none;
                    border: none;
                }
            }
        }
        .default-btn {
            width: 100%;
        }
    }
}
.copy-right-area {
    background-color: #000000;
    padding: 25px 0;
}
.copy-right-text {
    p {
        padding: 0;
        margin: 0;
        font-size: 15px;
        color: #a6a6a6;;
        a {
            color: var(--whiteColor);
            font-weight: 500;
            font-size: 15px;
            text-decoration: underline;
            &:hover {
                color: var(--mainColor);
            }
        }
    }
}
.copy-right-list {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            margin-right: 15px;
            a {
                color: #a3a3a3;
                &:hover {
                    color: var(--mainColor);
                } 
            }
        }
    }
}
/*=================================
Footer Area End
====================================*/
/*=================================
Page-navigation Area Style
====================================*/
.pagination-area {
    margin-bottom: 20px;
    .page-numbers {
        width: 38px;
        height: 38px;
        color: var(--titleColor);
        text-align: center;
        display: inline-block;
        border-radius: 50px;
        line-height: 38px;
        position: relative;
        margin-left: 3px;
        margin-right: 3px;
        font-size: 20px;
        font-weight: 500;
        &:hover {
            color: var(--mainColor);
        }
        i {
            position: relative;
            font-size: 30px;
            top: 5px;
            
        }
        &.current {
            color: var(--mainColor);
        }
    }
}
/*=================================
Page-navigation Area Style
====================================*/

/*=================================
Faq Area Style
====================================*/
.faq-area {
    background-color: #f5f5f5;
}
.faq-item {
    margin-bottom: 30px;
    h3 {
        font-size: 22px;
        margin-bottom: 15px;
        color: var(--titleColor);
        font-weight: 500;
    }
    p {
        margin-bottom: 0;
    }
}
.faq-section {
    .contact-card {
        background-color: none !important;
        &::before {
            display: none;
        }
    }
}
.faq-contact {
    .contact-form {
        background-color: #f5f5f5 !important;
        margin-bottom: 30px;
    }
}
/*=================================
Faq Area Style End
====================================*/

/*=================================
Contact Area 
====================================*/
.contact-area {
    padding-top: 84px;
    padding-bottom: 70px;
}
.contact-card {
    margin-bottom: 30px;
    padding: 0 30px;
    background-color: var(--whiteColor);
    position: relative;
    &::before {
        content: '';
        position: absolute;
        right: -10px;
        height: 180px;
        width: 2px;
        top: -5px;
        background-color: #ededed;
    }
    i {
        font-size: 60px;
        color: var(--mainColor);
        margin-bottom: 10px;
    }
    h3 {
        font-size: 16px;
        color: #727272;
        a {
            color: #727272;
            &:hover {
                color: var(--mainColor);
            }
        }
    }
}

.contact-area {
    .col-lg-4 {
        &:nth-child(3){
            .contact-card {
                &::before {
                    display: none;
                }
            }
        }
    }
}
.contact-map {
    position: relative;
}
.contact-map iframe {
    display: block;
    width: 100%;
    height: 820px;
}
.contact-wrap {
    position: absolute;
    top: 100px;
    right: 5%;
}
.contact-form {
    padding: 50px;
    max-width: 800px;
    margin: 0 auto 0;
    background-color: var(--whiteColor);
    span {
        color: var(--mainColor);
        line-height: 0;
        font-size: 14px;
        font-family: var(--sub-fontFamily);
        text-transform: uppercase;
    }
    h2 {
        font-size: 30px;
        margin-bottom: 20px;
        color: #234467;
        font-weight: 400;
    }
    .form-group {
        margin-bottom: 25px;
        position: relative;
        i {
            position: absolute;
            top: 14px;
            left: 15px;
            line-height: 1;
            font-size: 18px;
            color: #7f7f7f;
            z-index: 1;
        }
        .form-control {
            height: 50px;
            color: #7f7f7f;
            border: 1px solid #e8e8e8;
            background-color: #ffffff;
            border-radius: 0;
            font-size: 14px;
            padding: 10px 20px 10px 40px;
            width: 100%;
            &:focus {
                outline: none;
                border-color: #ffc0a7;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
        }
    }
    .with-errors {
        float: left;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 0;
        color: #f00;
        font-weight: 400;
        display: block;
    }
    .text-danger {
        font-size: 18px;
        margin-top: 15px;
    }
    .default-btn {
        border: 0;
        outline: none;
    }
}
.contact-form .form-group .form-control::-webkit-input-placeholder {
    color: #7f7f7f;
}
.contact-form .form-group .form-control:-ms-input-placeholder {
    color: #7f7f7f;
}
.contact-form .form-group .form-control::-ms-input-placeholder {
    color: #7f7f7f;
}
.contact-form .form-group .form-control::placeholder {
    color: #7f7f7f;
}
/*=================================
Contact Area End
====================================*/

/*=================================
User Area 
====================================*/
.user-section {
    .user-content {
        margin-bottom: 40px;
        img {
            margin-bottom: 25px;
        }
        h2 {
            color: var(--titleColor);
            font-size: 30px;
            b {
                font-weight: 300;
            }
        }
    }
    .tabs {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-block;
            line-height: initial;
            padding: 13px 50px 13px 50px;
            border-bottom: 1px solid #ededed;
            a {
                font-size: 16px;
                color: var(--titleColor);
                i {
                    color: var(--mainColor);
                    font-size: 18px;
                }
            }
            &.active {
                border-bottom: 1px solid var(--mainColor);
            }
            &.current {
                border-bottom: 1px solid var(--mainColor);
            }
        }
    }
    .user-all-form {
        margin-bottom: 30px;
        .contact-form {
            padding: 35px 50px !important;
            .agree-label {
                margin-top: 30px;
                float: left;
                label {
                    font-weight: 400;
                    color: #828282;
                    margin-left: 10px;
                }
            }
            .form-group {
                .form-control {
                    background-color: #f7f7f7 !important;
                }
            }
            .forget {
                margin-top: 30px;
                float: right;
                color: var(--mainColor);
                border-bottom: 1px solid var(--mainColor);
                font-weight: 400;
                &:hover {
                    color: var(--titleColor);
                    border-color: var(--titleColor);
                }
            }
            .account-desc {
                margin-top: 30px;
                font-weight: 400;
                text-align: center;
                margin-bottom: 0;
                a {
                    color: #828282;
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
            .user-all-btn {
                width: 100%;
            }
            .social-option {
                margin-top: 50px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    top: 12px;
                    left: 0;
                    width: 37%;
                    height: 1px;
                    background-color: #d5d5d5;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 12px;
                    right: 0;
                    width: 37%;
                    height: 1px;
                    background-color: #d5d5d5;
                }
                h3 {
                    font-size: 16px;
                    color: #828282;
                    font-weight: 400;
                    margin-bottom: 30px;
                }
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li {
                        display: inline-block;
                        a {
                            width: 140px;
                            height: 50px;
                            line-height: 50px;
                            color: var(--whiteColor);
                            border-radius: 50px;
                        }
                        &:nth-child(1) a {
                            background-color: #3b5998;
                            &:hover {
                                background-color: var(--mainColor);
                                animation: tada 1s linear;
                            }
                        }
                        &:nth-child(2) a {
                            background-color: #55acee;
                            &:hover {
                                background-color: var(--mainColor);
                                animation: tada 1s linear;
                            }
                        }
                        &:nth-child(3) a {
                            background-color: #3f729b;
                            &:hover {
                                background-color: var(--mainColor);
                                animation: tada 1s linear;
                            }
                        }
                    }
                }
            }
        }
    }
}
/*=================================
User Area End
====================================*/

/*=================================
404 Error Area
===================================*/
.error-area {
    height: 100vh;
    background-image: url(../img/inner-banner/inner-banner1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.8;
    }
    .error-content {
        max-width: 700px;
        background: var(--whiteColor);
        border-radius: 5px;
        margin: 0 auto;
        padding: 80px 50px 50px;
        text-align: center;
        position: relative;
        img {
            margin-bottom: 70px;
        }
        h1 {
            font-size: 150px;
            line-height: 0.5;
            font-weight: 600;
            color: var(--titleColor);
            span {
                color: var(--mainColor);
            }
        }
        h3 {
            margin: 50px 0 0;
            position: relative;
            color: var(--mainColor);
            font-size: 24px;
        }
        p {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 18px;
            max-width: 520px;
            margin-left: auto;
            margin-right: auto;
            color: var(--titleColor);
        }
    }
}
/*=================================
404 Error Area End
===================================*/

/*================================== 
Coming Soon Area 
====================================*/
.coming-soon-area {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-image: url(../img/inner-banner/inner-banner6.jpg);
    background-size: cover;
    background-position: center center;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.8;
    }
    .coming-soon-content {
        text-align: center;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 2;
        background-color: #ffffff;
        padding: 50px 30px;
        border: 5px solid #aec5df;
        img {
            margin-bottom: 30px;
        }
        h1 {
            margin-bottom: 0;
            color: var(--titleColor);
            font-size: 60px;
            margin-top: -18px;
        }
        p {
            font-size: 16px;
            max-width: 600px;
            margin-top: 15px;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
        }
        #timer {
            margin-top: 20px;
            div {
                display: inline-block;
                color: var(--titleColor);
                position: relative;
                margin-left: 35px;
                margin-right: 35px;
                font-size: 45px;
                font-weight: 700;
                span {
                    display: block;
                    text-transform: capitalize;
                    margin-top: -15px;
                    font-size: 16px;
                    font-weight: normal;
                }
                &:last-child {
                    margin-right: 0;
                    &::before {
                        display: none;
                    }  
                }
                &:first-child {
                    margin-left: 0;
                }
                &::before {
                    content: "";
                    position: absolute;
                    right: -50px;
                    top: -10px;
                    font-size: 70px;
                    color: #ffffff;
                }
            }
        }
        .newsletter-form {
            position: relative;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;
            .input-newsletter {
                display: block;
                width: 100%;
                height: 60px;
                border: none;
                background-color: rgba(255, 255, 255, 0.3);
                padding-left: 15px;
                color: #ffffff;
                outline: 0;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                border-radius: 0;
                border: 1px solid var(--optionalColor);
                color: #5d5d5d;
                &:focus {
                    border-color: var(--mainColor);
                }
            }
            .default-btn {
                border: 0;
                outline: 0;
            }
            button {
                position: absolute;
                right: 0;
                top: 0;
                height: 60px;
                padding: 0 30px;
                text-transform: uppercase;
                outline: 0;
                color: #ffffff;
                transition: 0.5s;
                font-weight: 500;
                font-size: 15px;
                cursor: pointer;
                &::after {
                    border-radius: 0;
                }
                &::before {
                    border-radius: 0;
                }
                &:hover {
                    color: #ffffff;
                    background-color: #190f3c;
                }
            }
        }
        ul {
            list-style: none;
            margin-top: 30px;
            padding: 0;
            li {
                display: inline-block;
                width: 45px;
                height: 45px;
                line-height: 50px;
                font-size: 18px;
                background-color: var(--mainColor);
                color: var(--whiteColor);
                border-radius: 50px;
                margin-right: 10px;
                a {
                    color: var(--whiteColor);
                }
                &:hover {
                    background-color: var(--titleColor);
                }
            }
        }
    }
    #validator-newsletter {
        text-align: left;
        color: #dc3545 !important;
    }
}
/*================================== 
Coming Soon Area End
====================================*/
.single-content {
    margin-bottom: 30px;
    h3 {
        font-size: 24px;
        color: var(--titleColor);
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;
    }
}
/*==================================
Preloader CSS 
=====================================*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: var(--mainColor);
} 
.spinner {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: var(--whiteColor);
    margin: 100px auto;
    animation: rotate-in 1.2s infinite ease-in-out;
}  
/*==================================
Preloader CSS End
=====================================*/

/*==================================
Back To Top Button 
=====================================*/
#toTop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
    display: none;
    z-index: 99;
} 
.top-btn {
    background-color: var(--mainColor);
    color: var(--whiteColor);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 0 15px var(--mainColor);
    font-size: 20px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    transition: .9s;
    &:hover{
        background: var(--titleColor);
        box-shadow: 0 0 15px var(--titleColor);
        color: #fff;
    }
}
/*==============================
Back To Top Button 
=================================*/

/*==============================
Animation  CSS 
=================================*/
@-webkit-keyframes ripple {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    75% {
      -webkit-transform: scale(1.75);
      transform: scale(1.75);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0;
    }
}
@keyframes ripple {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    75% {
      -webkit-transform: scale(1.75);
      transform: scale(1.75);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0;
    }
}

@-webkit-keyframes rotate-in {
    0% {
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
              transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
              transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
              transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
  
@keyframes rotate-in {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
                transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
                transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
                transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
/*==============================
Animation  CSS End
=================================*/