ul.listing_details {
    list-style: none;
    padding: 0;

    li{
        display: block;
        border-top: 0.8px solid #d9d5d5;
        clear:both;
        padding: 6px 0px;
    }
    li:after{
        clear: both;
    }
}