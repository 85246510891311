	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/07/2020 03:06
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-shopping-bags:before { content: "\f100"; }
.flaticon-loupe:before { content: "\f101"; }
.flaticon-contact:before { content: "\f102"; }
.flaticon-curve-arrow:before { content: "\f103"; }
.flaticon-place:before { content: "\f104"; }
.flaticon-vision:before { content: "\f105"; }
.flaticon-category:before { content: "\f106"; }
.flaticon-bake:before { content: "\f107"; }
.flaticon-hotel:before { content: "\f108"; }
.flaticon-barbell:before { content: "\f109"; }
.flaticon-store:before { content: "\f10a"; }
.flaticon-event:before { content: "\f10b"; }
.flaticon-flower:before { content: "\f10c"; }
.flaticon-like:before { content: "\f10d"; }
.flaticon-cursor:before { content: "\f10e"; }
.flaticon-position:before { content: "\f10f"; }
.flaticon-to-do-list:before { content: "\f110"; }
.flaticon-box:before { content: "\f111"; }
.flaticon-buildings:before { content: "\f112"; }
.flaticon-workflow:before { content: "\f113"; }
.flaticon-quote:before { content: "\f114"; }
.flaticon-email:before { content: "\f115"; }
.flaticon-verify:before { content: "\f116"; }
.flaticon-left:before { content: "\f117"; }
.flaticon-arrow:before { content: "\f118"; }
.flaticon-security:before { content: "\f119"; }
.flaticon-support:before { content: "\f11a"; }
.flaticon-web-page:before { content: "\f11b"; }
.flaticon-phone-call:before { content: "\f11c"; }
.flaticon-telephone:before { content: "\f11d"; }
.flaticon-email-1:before { content: "\f11e"; }
.flaticon-favorite:before { content: "\f11f"; }
.flaticon-writing:before { content: "\f120"; }
.flaticon-touch:before { content: "\f121"; }
.flaticon-gallery:before { content: "\f122"; }
.flaticon-worldwide:before { content: "\f123"; }
.flaticon-coffee-cup:before { content: "\f124"; }
.flaticon-wifi:before { content: "\f125"; }
.flaticon-parking:before { content: "\f126"; }
.flaticon-bread:before { content: "\f127"; }
.flaticon-left-arrow-1:before { content: "\f128"; }
.flaticon-arrow-1:before { content: "\f129"; }
.flaticon-email-2:before { content: "\f12a"; }
.flaticon-calendar:before { content: "\f12b"; }
.flaticon-filter:before { content: "\f12c"; }
.flaticon-list:before { content: "\f12d"; }
.flaticon-menu:before { content: "\f12e"; }
.flaticon-deal:before { content: "\f12f"; }
.flaticon-megaphone:before { content: "\f130"; }
.flaticon-diamond:before { content: "\f131"; }